<template>
  <!-- Dialog Add Partner -->
  <ib-dialog
    id="dialog-story-mode-add-partner"
    empty-header
    :visible="dialogAddPartnerVisible"
    fullscreen
    @open="openDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >
    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $tc('addNewPartner') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
      <!-- Header -->
      <add-dialog-header>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $tc('addNewPartner') }}
          </h2>
          <!-- /Title -->
        </template>
      </add-dialog-header>
      <!-- /Header -->

      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-3 d-block d-xl-none"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.partner.addDialog.guide.title')"
        :text="$t('pages.businessGuide.partner.addDialog.guide.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <!-- Ai Buddy -->
      <ai-buddy
        v-if="$breakpoint.lgAndDown"
        type="partners"
        class="ai-buddy-margin-bottom d-block d-xl-none"
        @suggestion="setSuggestion"
        @click="guideVisible = false"
      />
      <!-- Ai Buddy -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="grid-wrapper">
          <el-row :gutter="30">

            <!-- Left Side -->
            <el-col :md="12" :xl="7">

              <!-- Avatar setter -->
              <!--              <avatar-setter v-if="cropping" @click="cropImage" />-->
              <!-- /Avatar setter -->

              <!-- Form Wrapper -->
              <div class="product-form-wrapper">
                <!-- Avatar And Uploader -->
                <ib-avatar-uploader
                  ref="avatarUploader"
                  :image="form.image"
                  :default-image="require('@/assets/img/icons/story-mode/people.svg')"
                  @cropping="cropping = true"
                  @change="form.croppedImage = $event"
                  @crop="cropImage"
                  @delete="deleteImage"
                />
                <!-- /Avatar And Uploader -->

                <!-- Form Inner -->
                <div class="product-form-inner">

                  <!-- Form -->
                  <el-form ref="form" :model="form" @submit.native.prevent>

                    <!-- Product Or Service Name -->
                    <div class="d-flex align-items-center">
                      <ib-input
                        v-model="form.name"
                        class="flex-grow-1"
                        :label="$t('pages.businessGuide.partner.addDialog.fields.partnerName')"
                        :maxlength="50"
                      />
                      <ai-buddy-button-rounded
                        class="ai-buddy ml-2"
                        emit-event-buss-events
                      />
                    </div>
                    <!-- /Product Or Service Name -->

                    <!-- Description -->
                    <el-form-item>
                      <el-input
                        v-model="form.description"
                        type="textarea"
                        maxlength="500"
                        :rows="4"
                        class=""
                        :placeholder="$t('pages.businessGuide.partner.addDialog.fields.shortDescription')"
                        resize="none"
                      />
                    </el-form-item>
                    <!-- /Description -->

                  </el-form>
                  <!-- /Form -->

                </div>
                <!-- /Form Inner -->

              </div>
              <!-- /Form Wrapper -->

              <!-- Dialog Bottom -->
              <div class="my-4 my-md-0">

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <el-row :gutter="10" class="d-flex">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialog">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :disabled="form.name === ''"
                      :loading="loading"
                      @click="savePartner"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>
              </div>
              <!-- /Dialog Bottom -->
            </el-col>
            <!-- /Left Side -->

            <!-- Center -->
            <el-col :md="12" :xl="7">
              <template v-if="!getPartners.length">
                <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                  <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                  <p class="add-dialog-folder-text font-outfit-regular">
                    {{ $t('createdItemsWillAppearHere') }}
                  </p>
                </div>
              </template>

              <!-- Products Added -->
              <div ref="cardHolder" class="products-added">
                <ib-draggable
                  :list="getPartners"
                  draggable=".product"
                  @change="onChangePartnerOrder"
                  @start="onStartPartnerOrder"
                >
                  <!-- Products Added List -->
                  <div
                    v-for="partner in getPartners"
                    :key="partner.id"
                    class="product cursor-move"
                    @click="onEditPartner(partner)"
                  >

                    <!-- Product Image & Name -->
                    <div class="product-left">
                      <div>
                        <!-- Default Image -->
                        <div v-if="partner.image === null" class="img-wrapper">
                          <svg height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#3E474F" opacity=".1" d="M17 13h-2a5.93 5.93 0 00-3.87 1.43A5.54 5.54 0 007.44 13h-.88A5.57 5.57 0 001 18.56V19a1 1 0 001 1h20a1 1 0 001-1 6 6 0 00-6-6zM6.56 15h.88a3.49 3.49 0 012.4.95 5.81 5.81 0 00-.74 2V18H3a3.58 3.58 0 013.56-3zm4.59 3a2.1 2.1 0 01.06-.24v-.12a3.9 3.9 0 01.65-1.13A4 4 0 0115 15h2a4 4 0 013.87 3zM16 12a4 4 0 10-4-4 4 4 0 004 4zm0-6a2 2 0 11-2 2 2 2 0 012-2zm-9 6a4 4 0 10-4-4 4 4 0 004 4zm0-6a2 2 0 11-2 2 2 2 0 012-2z"/></svg>
                        </div>
                        <!-- /Default Image -->

                        <!-- Product Image -->
                        <div
                          v-else
                          class="img-wrapper img-wrapper-filled"
                          :style="cardImageBySize(partner.image, 'small')"
                        />
                        <!-- /Product Image -->
                      </div>

                      <!-- Product Name -->
                      <p class="product-name">
                        {{ partner.name }}
                      </p>
                      <!-- /Product Name -->

                    </div>
                    <!-- /Product Image & Name -->

                    <!-- Actions Dropdown -->
                    <actions-dropdown
                      ref="itemDropdownWrapper"
                      :entity="partner"
                      :delete-disabled="form.id === partner.id"
                      :edit-disabled="form.id === partner.id"
                      @edit="onEditPartner"
                      @copy="onCopyPartner"
                      @delete="deleteStoryModePartners($event.id)"
                    />
                    <!-- /Actions Dropdown -->

                  </div>
                </ib-draggable>
                <!-- /Products Added List -->
                <erase-icon-button
                  v-if="removeButtonVisible"
                  confirm
                  :loading="removeLoadingExamples"
                  @confirm="deleteExamples"
                />
              </div>
              <!-- /Products Added -->

            </el-col>
            <!-- /Center -->

            <!-- Right Side -->
            <el-col :xl="10">
              <!-- Info Guide -->
              <info-guide-add-dialog
                class="mb-3 d-none d-xl-block"
                :visible="guideVisible"
                :title="$t('pages.businessGuide.partner.addDialog.guide.title')"
                :text="$t('pages.businessGuide.partner.addDialog.guide.text')"
                @click="onToggleDialogGuide"
              />
              <!-- Info Guide -->

              <!-- Ai Buddy -->
              <ai-buddy
                v-if="$breakpoint.lgAndUp"
                type="partners"
                class="ai-buddy-margin-bottom d-none d-xl-block"
                @suggestion="setSuggestion"
                @click="guideVisible = false"
              />
              <!-- Ai Buddy -->
            </el-col>
            <!-- /Right Side -->
          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Dialog Add Partner -->
</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AiBuddyButtonRounded from '@/views/Home/StoryMode/Components/AiBuddyButtonRounded.vue'
// import AvatarSetter from '@/views/Home/StoryMode/Components/AvatarSetter'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import IbAvatarUploader from '@/components/_v2/IbAvatarUploader'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { mapActions, mapGetters } from 'vuex'
import { getImageBySize } from '@/helpers/imageSizeHelper'

export default {
  name: 'DialogAddPartner',

  components: {
    AiBuddy,
    AiBuddyButtonRounded,
    InfoGuideAddDialog,
    ActionsDropdown,
    AddDialogHeader,
    // AvatarSetter,
    EraseIconButton,
    IbAvatarUploader
  },

  mixins: [
    MixinCloseMenu,
    MixinDialog,
    MixinGuide,
    MixinIdeaRoles,
    MixinDeleteIdeaExamples
  ],

  props: {
    dialogAddPartnerVisible: {
      type: Boolean,
      default: false
    },
    partnerAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      removeLoadingExamples: false,
      cropping: false,
      guideVisible: false,
      form: {
        id: 0,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        ideaId: this.$store.state.idea.id,
        name: '',
        description: ''
      }
    }
  },

  computed: {
    ...mapGetters('idea', [
      'getPartners',
      'getHasItemsFromTemplate'
    ]),

    isDialogOpen () {
      return this.dialogAddPartnerVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getPartners')
    }
  },

  watch: {
    'form.name' () {
      this.form.name = this.form.name.replace(/^\s+/g, '')
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModePartners',
      'updateOrderNumber'
    ]),

    setSuggestion (suggestion) {
      this.form.name = suggestion.name
      this.form.description = suggestion.description
    },

    onStartPartnerOrder () {
      this.$refs.itemDropdownWrapper.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangePartnerOrder (data) {
      this.updateOrderNumber({
        type: 'partner',
        data,
        oldOrderData: this.getPartners,
        mutation: 'setPartners'
      })
    },

    closeDialog () {
      this.$emit('close-dialog-add-partner')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addPartnerGuide')
      if (this.partnerAction) {
        this.form.id = this.partnerAction.id
        this.form.ideaId = this.$store.state.idea.id
        this.form.image = this.partnerAction.image
        this.form.name = this.partnerAction.name
        this.form.description = this.partnerAction.description
      }
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        name: '',
        description: ''
      }
      this.focus = {
        name: false
      }
      this.cropping = false
      this.$refs.avatarUploader.reset()
    },

    resetGuide () {
      if (this.getPartners.length === 0) {
        this.guideVisible = false
      }
    },

    savePartner () {
      this.loading = true
      if (this.cropping) {
        this.cropImage()
      }
      if (this.form.id === 0) {
        this.addPartner()
      } else {
        this.updatePartner()
      }
    },

    addPartner () {
      delete this.form.uploadedImage
      this.$http.post('story-mode/concept/partner/partners', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addPartner', response.data.payload.partner)
            this.resetForm()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    updatePartner () {
      this.$http.put(`story-mode/concept/partner/partners/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updatePartner', response.data.payload.partner)
            this.resetForm()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    onEditPartner (partner) {
      this.resetForm()
      this.form = Object.assign({}, partner)
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'uploadedImage', null)
      this.$set(this.form, 'croppedImage', null)
      this.scrollToTop()
    },

    onCopyPartner (partner) {
      this.resetForm()
      this.form = Object.assign({}, partner)
      this.form.id = 0
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'uploadedImage', null)
      this.$set(this.form, 'croppedImage', null)
      this.scrollToTop()
    },

    deleteImage () {
      this.form.image = null
      this.form.uploadedImage = null
      this.form.croppedImage = null
    },

    cropImage () {
      this.cropping = false
      const image = this.$refs.avatarUploader.cropImage()
      if (image) {
        this.form.croppedImage = image.croppedImage
        this.form.uploadedImage = image.uploadedImage

        return
      }
      this.deleteImage()
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('partners')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
