<template>
  <div class="onboarding-type-step-wrapper">

    <div class="text-center">
      <img src="@/assets/img/onboarding/hands.svg" alt="hands-icon">
      <onboarding-wizard-heading
        :title="isFirstTime ? $t('pages.onBoarding.letsCreateYourFirstProject') : $t('pages.onBoarding.describeYourIdeaClearlyForBetterResults')"
        :description="isFirstTime ? $t('pages.onBoarding.inNextFewSteps') : $t('pages.onBoarding.providingPreciseInformationHelpsOurAi')"
        text-center
        top-space="23px"
      />
    </div>

    <!--    <onboarding-type-chooser-->
    <!--      v-if="isFirstTime"-->
    <!--      v-model="localData.onboardingType"-->
    <!--    />-->

    <!--    <div v-if="!isFirstTime" class="text-center">-->
    <!--      <img src="@/assets/img/onboarding/ai-icon.svg" alt="ai-icon">-->
    <!--      <onboarding-wizard-heading-->
    <!--        :title="`${$t('pages.onBoarding.aiAssistance.title')}`"-->
    <!--        :description="`${$t('pages.onBoarding.aiAssistance.subtitle')}`"-->
    <!--        top-space="23px"-->
    <!--      />-->
    <!--    </div>-->

    <!--    <Transition name="slide">-->
    <!--      <div v-show="isFullOnboarding">-->
    <!--        <onboarding-wizard-heading-->
    <!--          :title="isFirstTime ? $t('pages.onBoarding.aiAssistance.firstTime.title') : $t('pages.onBoarding.aiAssistance.title')"-->
    <!--          :description="$t('pages.onBoarding.aiAssistance.subtitle')"-->
    <!--          top-space="23px"-->
    <!--          :show-tick-mark="localData.useAi !== null"-->
    <!--        />-->

    <!--        <v-chip-group-->
    <!--          v-model="localData.useAi"-->
    <!--          active-class="primary&#45;&#45;text"-->
    <!--          column-->
    <!--          :mandatory="localData.useAi !== null"-->
    <!--          color="white"-->
    <!--        >-->
    <!--          <v-chip-->
    <!--            style="width: 133px; border: 1px solid #d9d9d9; height: 45px;"-->
    <!--            class="justify-content-center"-->
    <!--            filter-->
    <!--            label-->
    <!--            outlined-->
    <!--            :value="true"-->
    <!--            text-color="#292f4d"-->
    <!--          >-->
    <!--            {{ $t('yes') }}-->
    <!--          </v-chip>-->
    <!--          <v-chip-->
    <!--            style="width: 133px; border: 1px solid #d9d9d9; height: 45px;"-->
    <!--            class="justify-content-center"-->
    <!--            filter-->
    <!--            label-->
    <!--            outlined-->
    <!--            :value="false"-->
    <!--            text-color="#292f4d"-->
    <!--          >-->
    <!--            {{ $t('no') }}-->
    <!--          </v-chip>-->
    <!--        </v-chip-group>-->
    <!--      </div>-->
    <!--    </Transition>-->
  </div>
</template>

<script>
// import OnboardingTypeChooser from '@/views/Onboarding/Components/OnboardingType/OnboardingTypeChooser.vue'
import OnboardingTypesEnum from '@/constants/OnboardingTypesEnum'
import OnboardingWizardHeading from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardHeading.vue'

export default {
  name: 'OnboardingTypeStep',

  components: {
    OnboardingWizardHeading
    // OnboardingTypeChooser
  },

  inject: ['getIsQuickStart', 'getFirstTime'],

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          onboardingType: null,
          useAi: null,
          useTemplate: null,
          category: null
        }
      }
    }
  },

  data () {
    return {
      localData: {
        onboardingType: null,
        useAi: null,
        useTemplate: null,
        category: null
      }
    }
  },

  computed: {
    isFullOnboarding () {
      return this.getIsQuickStart() !== null && !this.getIsQuickStart()
    },

    isFirstTime () {
      return this.getFirstTime()
    }
  },

  watch: {
    'localData.onboardingType' (newValue) {
      this.localData.useTemplate = newValue === OnboardingTypesEnum.QUICK_START ? true : null
      this.localData.category = newValue === OnboardingTypesEnum.QUICK_START
        ? Number(process.env.VUE_APP_ONBOARDING_QUICK_START_CATALOGUE_IDEA)
        : null
    }
  },

  created () {
    this.localData = this.data
  }
}
</script>

<style scoped lang="scss">
.onboarding-type-step-wrapper {
  padding-bottom: 10px;
}
</style>
