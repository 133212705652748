<template>
  <!-- Dialog Add Customer -->
  <div id="dialog-story-mode-add-customer">
    <ib-dialog
      empty-header
      :visible="dialogAddCustomerVisible"
      :fullscreen="true"
      @open="openDialog"
      @opened="openedDialog"
      @close="closeDialog"
      @closed="closedDialog"
    >
      <!-- Mobile title -->
      <template #title>
        <ib-dialog-container class="d-md-none">
          <h2>
            {{ $tc('addTargetCustomer') }}
          </h2>
        </ib-dialog-container>
      </template>
      <!-- /Mobile title -->

      <!-- Content Wrapper -->
      <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
        <!-- Header -->
        <add-dialog-header>
          <template #left>
            <!-- Title -->
            <h2 class="m-0">
              {{ $tc('addTargetCustomer') }}
            </h2>
            <!-- /Title -->
          </template>

        </add-dialog-header>
        <!-- /Header -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.customers.addDialog.guide.title')"
          :text="$t('pages.businessGuide.customers.addDialog.guide.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Ai Buddy -->
        <ai-buddy
          v-if="$breakpoint.lgAndDown"
          type="customers"
          class="ai-buddy-margin-bottom d-block d-xl-none"
          @suggestion="form.name = $event"
          @click="guideVisible = false"
        />
        <!-- Ai Buddy -->

        <!-- Inner Wrapper -->
        <div class="inner-wrapper">
          <div class="grid-wrapper">
            <el-row :gutter="30">

              <!-- Left Side -->
              <el-col :md="12" :xl="7">

                <!-- Avatar setter -->
                <!--                <avatar-setter v-if="cropping" @click="cropImage" />-->
                <!-- /Avatar setter -->

                <!-- Form Wrapper -->
                <div class="customer-form-wrapper">
                  <!-- Avatar And Uploader -->
                  <ib-avatar-uploader
                    ref="avatarUploader"
                    :image="form.image"
                    :default-image="require('@/assets/img/icons/story-mode/customer.svg')"
                    @cropping="cropping = true"
                    @change="form.croppedImage = $event"
                    @crop="cropImage"
                    @delete="deleteImage"
                  />
                  <!-- /Avatar And Uploader -->

                  <!-- Customer Form -->
                  <div class="customer-form-inner">

                    <!-- Form -->
                    <el-form ref="ruleForm" :model="form">

                      <!-- Customer Name -->
                      <div class="d-flex align-items-center">
                        <ib-input
                          v-model="form.name"
                          class="flex-grow-1"
                          :label="$t('pages.businessGuide.customers.addDialog.fields.nameOfTargetCustomers')"
                          :maxlength="50"
                        />
                        <ai-buddy-button-rounded
                          class="ai-buddy ml-2"
                          emit-event-buss-events
                        />
                      </div>
                      <!-- /Customer Name -->

                      <!-- Customer Description -->
                      <el-form-item>
                        <el-input
                          v-model="form.description"
                          type="textarea"
                          :rows="4"
                          maxlength="500"
                          :placeholder="$t('pages.businessGuide.customers.addDialog.fields.shortDescription')"
                          resize="none"
                        />
                      </el-form-item>
                      <!-- /Customer Description -->

                    </el-form>
                    <!-- Pain Points Title -->
                    <h4>{{ $t('pages.businessGuide.customers.addDialog.painPoints.title') }}</h4>
                    <!-- /Pain Points Title -->

                    <!-- Pain Points Form -->
                    <el-form>
                      <el-input
                        v-model="painPointsValue"
                        class="pain-points large"
                        type="textarea"
                        maxlength="250"
                        :rows="3"
                        :placeholder="$t('pages.businessGuide.customers.addDialog.painPoints.enterHere')"
                        @keyup.native="managePainPoints"
                      />
                    </el-form>
                    <!--/Pain Points Form -->

                    <!-- Attributes -->
                    <h4>{{ $t('pages.businessGuide.customers.addDialog.attributes.title') }}</h4>
                    <!-- /Attributes -->

                    <!-- Attributes Slider Block -->
                    <div
                      v-for="attribute in form.attributes"
                      :key="attribute.id"
                      class="slider-block"
                    >

                      <!-- Top Of The Slider Block -->
                      <div class="top">

                        <!-- Name -->
                        <div>
                          <p>{{ attribute.title }}</p>
                        </div>
                        <!-- /Name -->

                        <!-- Value -->
                        <p>
                          {{ attribute.value }}/5
                        </p>
                        <!-- /Value -->

                      </div>
                      <!-- /Top Of The Slider Block -->

                      <!-- Bottom Of The Slider Block -->
                      <div class="bottom">
                        <el-slider
                          v-model="attribute.value"
                          :step="1"
                          :max="5"
                          :show-tooltip="false"
                        />
                      </div>
                      <!-- /Bottom Of The Slider Block -->

                    </div>
                    <!-- /Attributes Slider Block -->

                  </div>
                  <!-- /Customer Form -->

                </div>
                <!-- /Form Wrapper -->

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <el-row :gutter="10" class="d-flex mb-4">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialog">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :disabled="!isAddEnabled"
                      :loading="loading"
                      @click="saveCustomer"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>
              </el-col>
              <!-- /Left Side -->

              <!-- Center -->
              <el-col :md="12" :xl="7">
                <template v-if="!$store.state.idea.storyMode.research.customers.customers.length">
                  <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                    <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                    <p class="add-dialog-folder-text font-outfit-regular">
                      {{ $t('createdItemsWillAppearHere') }}
                    </p>
                  </div>
                </template>

                <!-- Customers Added -->
                <div ref="cardHolder" class="customers-added">

                  <!-- Customers Added List -->
                  <ib-draggable
                    :list="$store.state.idea.storyMode.research.customers.customers"
                    draggable=".customer-card"
                    @change="onChangeCustomersOrder"
                    @start="onStartCustomersOrder"
                  >
                    <div
                      v-for="customer in $store.state.idea.storyMode.research.customers.customers"
                      :key="customer.id"
                      class="customer-card cursor-move"
                      @click="onEditCustomer(customer)"
                    >

                      <!-- Customer Card Top -->
                      <div class="card-top">

                        <!-- Customer Flex Inner -->
                        <div class="flex-inner">

                          <!-- Customer Image -->
                          <div>
                            <div
                              class="card-image-round" :class="{ 'default': customer.image === null}"
                              :style="cardImageBySize(customer.image, 'small')"
                            />
                          </div>
                          <!-- /Customer Image -->

                          <!-- Customer Name -->
                          <div>
                            <h3>{{ customer.name }}</h3>
                          </div>
                          <!-- /Customer Name -->

                        </div>
                        <!-- /Customer Flex Inner -->

                        <!-- Priority & Action Items -->
                        <div class="flex-inner">

                          <!-- Priority -->
                          <div
                            class="priority"
                            :class="[getCustomerLevel(customer) === $t('medium') ? 'medium': '' || getCustomerLevel(customer) === $t('low') ? 'low': '']"
                          >

                            <!-- Priority Title -->
                            <p class="title">
                              {{ getCustomerLevel(customer) }} <span>{{ $t('priority') }}</span>
                            </p>
                            <!-- /Priority Title -->

                          </div>
                          <!-- /Priority -->

                          <!-- Actions Dropdown -->
                          <actions-dropdown
                            ref="itemDropdownWrapper"
                            :entity="customer"
                            :delete-disabled="form.id === customer.id"
                            :edit-disabled="form.id === customer.id"
                            @edit="onEditCustomer"
                            @copy="onCopyCustomer"
                            @delete="deleteStoryModeCustomers($event.id)"
                          />
                          <!-- /Actions Dropdown -->

                        </div>
                        <!-- /Priority & Action Items -->

                      </div>
                      <!-- /Customer Card Top -->

                    </div>
                  </ib-draggable>
                  <!-- /Customers Added List -->
                  <erase-icon-button
                    v-if="removeButtonVisible"
                    confirm
                    :loading="removeLoadingExamples"
                    @confirm="deleteExamples"
                  />
                </div>
                <!-- /Customers Added -->

              </el-col>
              <!-- /Center -->

              <!-- Right Side -->
              <el-col :xl="10">
                <!-- Info Guide -->
                <info-guide-add-dialog
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.customers.addDialog.guide.title')"
                  :text="$t('pages.businessGuide.customers.addDialog.guide.text')"
                  @click="onToggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Ai Buddy -->
                <ai-buddy
                  v-if="$breakpoint.lgAndUp"
                  type="customers"
                  class="ai-buddy-margin-bottom d-none d-xl-block"
                  @suggestion="form.name = $event"
                  @click="guideVisible = false"
                />
                <!-- Ai Buddy -->
              </el-col>
              <!-- /Right Side -->
            </el-row>
          </div>
        </div>
        <!-- /Inner Wrapper -->

      </ib-dialog-container>
      <!-- /Content Wrapper -->

    </ib-dialog>
  </div>
  <!-- /Dialog Add Customer -->

</template>

<script>
import ActionsDropdown from '../../../Components/ActionsDropdown'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AiBuddyButtonRounded from '@/views/Home/StoryMode/Components/AiBuddyButtonRounded.vue'
// import AvatarSetter from '@/views/Home/StoryMode/Components/AvatarSetter'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import IbAvatarUploader from '@/components/_v2/IbAvatarUploader'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { getImageBySize } from '@/helpers/imageSizeHelper'

import { mapActions, mapGetters } from 'vuex'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'

export default {
  name: 'DialogAddCustomer',

  components: {
    AiBuddy,
    AiBuddyButtonRounded,
    InfoGuideAddDialog,
    ActionsDropdown,
    AddDialogHeader,
    // AvatarSetter,
    EraseIconButton,
    IbAvatarUploader
  },

  mixins: [
    MixinCloseMenu,
    MixinDeleteIdeaExamples,
    MixinDialog,
    MixinGuide,
    MixinIdeaRoles
  ],

  props: {
    dialogAddCustomerVisible: {
      type: Boolean,
      default: false
    },
    customerAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      removeLoadingExamples: false,
      cropping: false,
      guideVisible: false,
      painPointsValue: '',
      form: {
        id: 0,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        ideaId: this.$store.state.idea.id,
        name: '',
        description: '',
        // TODO - REFACTOR
        // painPoints: [
        //   { id: 1, value: '' },
        //   { id: 2, value: '' },
        //   { id: 3, value: '' }
        // ],
        painPoints: [
          { value: '' },
          { value: '' },
          { value: '' }
        ],
        attributes: [
          { id: 1, title: this.$t('pages.businessGuide.customers.addDialog.attributes.segmentSize'), value: 0 },
          { id: 2, title: this.$t('pages.businessGuide.customers.addDialog.attributes.willingness'), value: 0 },
          { id: 3, title: this.$t('pages.businessGuide.customers.addDialog.attributes.accessibility'), value: 0 }
        ]
      }
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    isAddEnabled () {
      let painPointPopulated = true
      const namePopulated = this.form.name !== ''

      for (let i = 0; i < this.form.painPoints.length; i++) {
        if (this.form.painPoints[i].value) {
          painPointPopulated = true
          break
        }
      }

      return painPointPopulated && namePopulated
    },

    isDialogOpen () {
      return this.dialogAddCustomerVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getCustomers')
    }
  },

  watch: {
    'form.name' () {
      this.form.name = this.form.name.replace(/^\s+/g, '')
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeCustomers',
      'updateOrderNumber'
    ]),

    onStartCustomersOrder () {
      this.$refs.itemDropdownWrapper.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangeCustomersOrder (data) {
      this.updateOrderNumber({
        type: 'customers',
        data,
        oldOrderData: this.$store.getters['idea/getCustomers'],
        mutation: 'setCustomers'
      })
    },

    closeDialog () {
      this.$emit('close-dialog-add-customer')
    },

    closedDialog () {
      this.resetForm()
      this.removePainPoints()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addCustomerGuide')
      if (this.customerAction) {
        this.form.id = this.customerAction.id
        this.form.ideaId = this.$store.state.idea.id
        this.form.image = this.customerAction.image
        this.form.name = this.customerAction.name
        this.form.description = this.customerAction.description
        this.form.painPoints = [
          { id: 1, value: '' },
          { id: 2, value: '' },
          { id: 3, value: '' }
        ]
        const valuesForTextarea = []
        for (let i = 0; i < this.customerAction.painPoints.length; i++) {
          this.form.painPoints[i].value = this.customerAction.painPoints[i].value
          valuesForTextarea.push(this.customerAction.painPoints[i].value)
        }

        this.painPointsValue = valuesForTextarea.join('\n')

        this.form.attributes = [
          { id: 1, title: this.$t('pages.businessGuide.customers.addDialog.attributes.segmentSize'), value: this.customerAction.segmentSize },
          { id: 2, title: this.$t('pages.businessGuide.customers.addDialog.attributes.willingness'), value: this.customerAction.willingnessToPay },
          { id: 3, title: this.$t('pages.businessGuide.customers.addDialog.attributes.accessibility'), value: this.customerAction.accessibility }
        ]
      }
    },

    openedDialog () {
      if (this.customerAction) {
        for (let i = 0; i < this.customerAction.painPoints.length - 1; i++) {
          this.addPainPoints()
        }
      }
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    resetForm () {
      this.form = {
        id: 0,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        ideaId: this.$store.state.idea.id,
        name: '',
        description: '',
        painPoints: [
          { id: 1, value: '' },
          { id: 2, value: '' },
          { id: 3, value: '' }
        ],
        attributes: [
          { id: 1, title: this.$t('pages.businessGuide.customers.addDialog.attributes.segmentSize'), value: 0 },
          { id: 2, title: this.$t('pages.businessGuide.customers.addDialog.attributes.willingness'), value: 0 },
          { id: 3, title: this.$t('pages.businessGuide.customers.addDialog.attributes.accessibility'), value: 0 }
        ]
      }
      this.painPointsValue = ''
      this.cropping = false
      this.$refs.avatarUploader.reset()
    },

    resetGuide () {
      if (this.$store.getters['idea/getCustomers'].length === 0) {
        this.guideVisible = false
      }
    },

    saveCustomer () {
      this.loading = true
      if (this.cropping) {
        this.cropImage()
      }
      if (this.form.id === 0) {
        this.addCustomer()
      } else {
        this.updateCustomer()
      }
    },

    addCustomer () {
      this.getPainPointsFromTextarea()
      delete this.form.uploadedImage
      this.$http.post('story-mode/research/customers/customers', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addCustomer', response.data.payload.customer)
            if (this.form.updateOtherAdvantagesTitle === true) {
              this.$store.commit('idea/updateCustomersAdvantagesTitles', response.data.payload.customer)
            }
            this.resetForm()
            this.removePainPoints()
            this.scrollToTop()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    updateCustomer () {
      this.getPainPointsFromTextarea()
      this.$http.put(`story-mode/research/customers/customers/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateCustomer', response.data.payload.customer)
            if (this.form.updateOtherAdvantagesTitle === true) {
              this.$store.commit('idea/updateCustomersAdvantagesTitles', response.data.payload.customer)
            }
            this.resetForm()
            this.removePainPoints()
            this.scrollToTop()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    onEditCustomer (customer) {
      this.copyCustomerToForm(customer)
    },

    onCopyCustomer (customer) {
      this.copyCustomerToForm(customer)
      this.form.id = 0
    },

    copyCustomerToForm (customer) {
      const attributes = this.form.attributes
      this.resetForm()
      this.removePainPoints()
      this.form = Object.assign({}, customer)
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'uploadedImage', null)
      this.$set(this.form, 'croppedImage', null)
      this.form.painPoints = [
        { id: 1, value: '' },
        { id: 2, value: '' },
        { id: 3, value: '' }
      ]
      // TODO - REFACTOR
      // for (let i = 0; i < customer.painPoints.length; i++) {
      //   this.form.painPoints[i].value = customer.painPoints[i].value
      // }

      const valuesForTextarea = []
      for (let i = 0; i < customer.painPoints.length; i++) {
        this.form.painPoints[i].value = customer.painPoints[i].value
        valuesForTextarea.push(customer.painPoints[i].value)
      }

      for (let i = 0; i < customer.painPoints.length - 1; i++) {
        this.addPainPoints()
      }

      this.painPointsValue = valuesForTextarea.join('\n')
      attributes[0].value = customer.segmentSize
      attributes[1].value = customer.willingnessToPay
      attributes[2].value = customer.accessibility
      this.form.attributes = attributes
      this.scrollToTop()
    },

    deleteImage () {
      this.form.image = null
      this.form.uploadedImage = null
      this.form.croppedImage = null
    },

    cropImage () {
      this.cropping = false
      const image = this.$refs.avatarUploader.cropImage()
      if (image) {
        this.form.croppedImage = image.croppedImage
        this.form.uploadedImage = image.uploadedImage

        return
      }
      this.deleteImage()
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    getCustomerLevel (customer) {
      // noinspection JSUnresolvedVariable
      const attributesLevel = customer.segmentSize + customer.willingnessToPay + customer.accessibility

      if (attributesLevel <= 5) {
        return this.$t('low')
      } else if (attributesLevel <= 10) {
        return this.$t('medium')
      }

      return this.$t('high')
    },

    managePainPoints (e) {
      if (e.which === 13) {
        const text = this.painPointsValue
        let splittedText = text.split('\n').splice(0, 3)
        const iconHolder = document.getElementsByClassName('pain-points large')[0]
        const spanIcon = document.createElement('span')
        const spanIconElement = document.getElementsByClassName('emoji-holder')

        this.painPointsValue = splittedText.join('\n')
        spanIcon.classList.add('emoji-holder')
        splittedText = splittedText.filter(el => el !== '')

        if (spanIconElement.length < 2) {
          const currentElement = iconHolder.appendChild(spanIcon)
          currentElement.id = spanIconElement.length.toString()

          if (spanIconElement.length === 2) {
            currentElement.style.top = '66px'
          }
        }
      }

      if (e.which === 8 || e.which === 46) {
        const textareaValues = this.painPointsValue.split('\n')
        const spanIconElement = document.getElementsByClassName('emoji-holder')

        if ((spanIconElement.length + 1) !== textareaValues.length && spanIconElement.length !== 0) {
          if (textareaValues.length < 2) {
            const itemToRemove = document.querySelectorAll('.emoji-holder')
            for (let i = 0; i < itemToRemove.length; i++) {
              itemToRemove[i].remove()
            }
          } else {
            const itemToRemove = document.getElementById(textareaValues.length.toString())
            itemToRemove.remove()
          }
        }
      }
    },

    removePainPoints () {
      const itemToRemove = document.querySelectorAll('.emoji-holder')
      for (let i = 0; i < itemToRemove.length; i++) {
        itemToRemove[i].remove()
      }
    },

    addPainPoints () {
      const iconHolder = document.getElementsByClassName('pain-points large')[0]
      const spanIcon = document.createElement('span')
      const spanIconElement = document.getElementsByClassName('emoji-holder')

      spanIcon.classList.add('emoji-holder')

      if (spanIconElement.length < 2) {
        const currentElement = iconHolder.appendChild(spanIcon)
        currentElement.id = spanIconElement.length.toString()

        if (spanIconElement.length === 2) {
          currentElement.style.top = '66px'
        }
      }
    },

    getPainPointsFromTextarea () {
      const textareaValues = this.painPointsValue.split('\n')

      this.form.painPoints = [
        { id: 1, value: '' },
        { id: 2, value: '' },
        { id: 3, value: '' }
      ]

      for (let i = 0; i < textareaValues.length; i++) {
        this.form.painPoints[i].value = textareaValues[i]
      }
    },

    preventSubmitOnEnter (e) {
      if (e) e.preventDefault()
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('customers')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
