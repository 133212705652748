<template>
  <!-- Dialog Add Competitor -->
  <div id="dialog-story-mode-add-competitor">
    <ib-dialog
      ref="dialog"
      empty-header
      :visible="dialogAddCompetitorVisible"
      :fullscreen="true"
      @open="openDialog"
      @close="closeDialog"
      @closed="closedDialog"
    >
      <!-- Mobile title -->
      <template #title>
        <ib-dialog-container class="d-md-none">
          <h2>
            {{ $tc('addCompetitor') }}
          </h2>
        </ib-dialog-container>
      </template>
      <!-- /Mobile title -->

      <!-- Content Wrapper -->
      <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
        <!-- Header -->
        <add-dialog-header>
          <template #left>
            <!-- Title -->
            <h2 class="m-0">
              {{ $tc('addCompetitor') }}
            </h2>
            <!-- /Title -->
          </template>

        </add-dialog-header>
        <!-- /Header -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.competitors.addDialog.guide.title')"
          :text="$t('pages.businessGuide.competitors.addDialog.guide.text')"
          @click="onToggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Ai Buddy -->
        <ai-buddy
          v-if="$breakpoint.lgAndDown"
          type="competitors"
          class="ai-buddy-margin-bottom d-block d-xl-none"
          @suggestion="form.name = $event"
          @click="guideVisible = false"
        />
        <!-- Ai Buddy -->

        <!-- Inner Wrapper -->
        <div class="inner-wrapper">
          <div class="grid-wrapper">
            <el-row :gutter="30">

              <!-- Left Side -->
              <el-col :md="12" :xl="7">

                <!-- Avatar setter -->
                <!--                <avatar-setter v-if="cropping" @click="cropImage" />-->
                <!-- /Avatar setter -->

                <!-- Form Wrapper -->
                <div class="competitor-form-wrapper">
                  <!-- Avatar And Uploader -->
                  <ib-avatar-uploader
                    ref="avatarUploader"
                    :image="form.image"
                    :default-image="require('@/assets/img/icons/story-mode/competitor.svg')"
                    @cropping="cropping = true"
                    @change="form.croppedImage = $event"
                    @crop="cropImage"
                    @delete="deleteImage"
                  />
                  <!-- /Avatar And Uploader -->

                  <!-- Switch Wrapper -->
                  <div class="switch-wrapper">
                    <form-switcher
                      v-model="form.direct"
                      :options="[{
                        title: $t('pages.businessGuide.competitors.addDialog.switch.direct'),
                        value: true
                      },{
                        title: $t('pages.businessGuide.competitors.addDialog.switch.indirect'),
                        value: false
                      }]"
                    />
                  </div>
                  <!-- /Switch Wrapper -->

                  <!-- Form Inner -->
                  <div class="competitor-form-inner">

                    <!-- Form -->
                    <el-form ref="ruleForm" :model="form">

                      <!-- Competitor Name -->
                      <div class="d-flex align-items-center">
                        <ib-input
                          v-model="form.name"
                          class="flex-grow-1"
                          :label="$t('pages.businessGuide.competitors.addDialog.fields.competitorName')"
                          :maxlength="50"
                        />
                        <ai-buddy-button-rounded
                          class="ai-buddy ml-2"
                          emit-event-buss-events
                        />
                      </div>
                      <!-- /Competitor Name -->

                      <!-- Customer Description -->
                      <el-form-item class="mb-3">
                        <el-input
                          v-model="form.description"
                          type="textarea"
                          :rows="4"
                          maxlength="500"
                          :placeholder="$t('pages.businessGuide.competitors.addDialog.fields.shortDescription')"
                          resize="none"
                        />
                      </el-form-item>
                      <!-- /Customer Description -->

                      <!-- Competitive Advantages Title -->
                      <h3>{{ $t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.title') }}</h3>
                      <!-- /Competitive Advantages Title -->

                      <!-- Competitive Advantages Slider Block -->
                      <div
                        v-for="advantage in form.advantages"
                        :key="advantage.id"
                        class="slider-block"
                        :class="{ 'editable': advantageinputvisibleid === advantage.id }"
                      >

                        <!-- Top Of The Slider Block -->
                        <div class="top">

                          <!-- Slider Block Name Segment -->
                          <div @click="activateAdvantageInput(advantage)">

                            <!-- Slider Block Name Input -->
                            <el-input
                              v-show="advantageinputvisibleid === advantage.id"
                              :ref="'advantageInput' + advantage.id"
                              v-model="advantage.title"
                              :focus="advantageinputvisibleid === advantage.id"
                              class="borderless-input"
                              autofocus
                              type="text"
                              @keyup.native.enter="advantageinputvisibleid = null"
                              @blur="advantageinputvisibleid = null"
                              @input="form.updateOtherAdvantagesTitle = true"
                            />
                            <!-- /Slider Block Name Input -->

                            <!-- Slider Block Name Title -->
                            <p
                              v-show="advantageinputvisibleid !== advantage.id"
                              style="margin-left: 1px;"
                              @click="activateAdvantageInput(advantage)"
                            >
                              {{ advantage.title }}
                            </p>
                            <!-- /Slider Block Name Title -->

                            <!-- Edit Name Title Icon -->
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-show="advantageinputvisibleid !== advantage.id" v-bind:svg-inline="''" @click="activateAdvantageInput(advantage)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
                            <!-- /Edit Name Title Icon -->

                          </div>
                          <!-- /Slider Block Name Segment -->

                          <!-- Slider Block Strength Title  -->
                          <p>{{ calculateAdvantageStrength(advantage) }}</p>
                          <!-- /Slider Block Strength Title  -->

                        </div>
                        <!-- /Top Of The Slider Block -->

                        <!-- Bottom Of The Slider Block -->
                        <div class="bottom">

                          <!-- Slider Block -->
                          <el-slider
                            v-model="advantage.value"
                            :show-tooltip="false"
                            :max="120"
                          />
                          <!-- /Slider Block -->

                        </div>
                        <!-- /Bottom Of The Slider Block -->

                      </div>
                      <!-- /Competitive Advantages Slider Block -->

                    </el-form>
                    <!-- /Form -->

                  </div>
                  <!-- /Form Inner -->

                </div>
                <!-- /Form Wrapper -->

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <el-row :gutter="10" class="d-flex mb-4">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialog">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :loading="loading"
                      :disabled="form.name === ''"
                      @click="saveCompetitor"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>

              </el-col>
              <!-- /Left Side -->

              <!-- Center -->
              <el-col :md="12" :xl="7">
                <template v-if="!$store.state.idea.storyMode.research.competitors.competitors.length">
                  <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                    <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                    <p class="add-dialog-folder-text font-outfit-regular">
                      {{ $t('createdItemsWillAppearHere') }}
                    </p>
                  </div>
                </template>

                <!-- Competitors Added -->
                <div ref="cardHolder" class="competitors-added">

                  <!-- Competitors Added List -->
                  <ib-draggable
                    :list="$store.state.idea.storyMode.research.competitors.competitors"
                    draggable=".competitor-card"
                    @change="onChangeCompetitorsOrder"
                    @start="onStartCompetitorsOrder"
                  >
                    <div
                      v-for="competitor in $store.state.idea.storyMode.research.competitors.competitors"
                      :key="competitor.id"
                      class="competitor-card cursor-move"
                      @click="onEditCompetitor(competitor)"
                    >

                      <!-- Competitor Card Top -->
                      <div class="card-top">

                        <!-- Competitor Flex Inner -->
                        <div class="flex-inner">

                          <!-- Competitor Image -->
                          <div>
                            <div
                              class="card-image-round" :class="{ 'default': competitor.image === null}"
                              :style="cardImageBySize(competitor.image, 'small')"
                            />
                          </div>
                          <!-- /Competitor Image -->

                          <!-- Competitor Card Title & Influence -->
                          <div>

                            <!-- Competitor Name -->
                            <h3>{{ competitor.name }}</h3>
                            <!-- /Competitor Name -->

                            <!-- Competitor Card Influence -->
                            <p>{{ competitor.direct === true ? $t('direct') : $t('indirect') }}</p>
                            <!-- /Competitor Card Influence -->

                          </div>
                          <!-- /Competitor Card Title & Influence -->

                        </div>
                        <!-- /Competitor Flex Inner -->

                        <!-- Threat Level & Action Items -->
                        <div class="flex-inner">

                          <!-- Threat Level -->
                          <div
                            class="threat"
                            :class="[getCompetitorLevel(competitor) === $t('medium') ? 'medium': '' || getCompetitorLevel(competitor) === $t('low') ? 'low': '']"
                          >

                            <!-- Threat Level Title -->
                            <p class="title">
                              {{ getCompetitorLevel(competitor) }} <span>{{ $t('dialogs.addCompetitor.threat') }}</span>
                            </p>
                            <!-- /Threat Level Title -->

                          </div>
                          <!-- /Threat Level -->

                          <!-- Actions Dropdown -->
                          <actions-dropdown
                            ref="itemDropdownWrapper"
                            :entity="competitor"
                            :delete-disabled="form.id === competitor.id"
                            :edit-disabled="form.id === competitor.id"
                            @edit="onEditCompetitor"
                            @copy="onCopyCompetitor"
                            @delete="deleteStoryModeCompetitors($event.id)"
                          />
                          <!-- /Actions Dropdown -->

                        </div>
                        <!-- /Threat Level & Action Items -->

                      </div>
                      <!-- /Competitor Card Top -->

                    </div>
                  </ib-draggable>
                  <!-- /Competitors Added List -->

                  <erase-icon-button
                    v-if="removeButtonVisible"
                    confirm
                    :loading="removeLoadingExamples"
                    @confirm="deleteExamples"
                  />
                </div>
                <!-- /Competitors Added -->

              </el-col>
              <!-- /Center -->

              <!-- Right Side -->
              <el-col :xl="10">
                <!-- Info Guide -->
                <info-guide-add-dialog
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.competitors.addDialog.guide.title')"
                  :text="$t('pages.businessGuide.competitors.addDialog.guide.text')"
                  @click="onToggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Ai Buddy -->
                <ai-buddy
                  v-if="$breakpoint.lgAndUp"
                  type="competitors"
                  class="ai-buddy-margin-bottom d-none d-xl-block"
                  @suggestion="form.name = $event"
                  @click="guideVisible = false"
                />
                <!-- Ai Buddy -->
              </el-col>
              <!-- /Right Side -->
            </el-row>
          </div>
        </div>
        <!-- /Inner Wrapper -->

      </ib-dialog-container>
      <!-- /Content Wrapper -->

    </ib-dialog>
  </div>
  <!-- /Dialog Add Competitor -->

</template>

<script>
import ActionsDropdown from '@/views/Home/StoryMode/Components/ActionsDropdown'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AiBuddyButtonRounded from '@/views/Home/StoryMode/Components/AiBuddyButtonRounded.vue'
// import AvatarSetter from '@/views/Home/StoryMode/Components/AvatarSetter'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import IbAvatarUploader from '@/components/_v2/IbAvatarUploader'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import { getImageBySize } from '@/helpers/imageSizeHelper'

import { mapActions, mapGetters } from 'vuex'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'

export default {
  name: 'DialogAddCompetitor',

  components: {
    AiBuddy,
    AiBuddyButtonRounded,
    InfoGuideAddDialog,
    ActionsDropdown,
    AddDialogHeader,
    // AvatarSetter,
    EraseIconButton,
    FormSwitcher,
    IbAvatarUploader
  },

  mixins: [
    MixinCloseMenu,
    MixinDeleteIdeaExamples,
    MixinDialog,
    MixinDialog,
    MixinGuide,
    MixinIdeaRoles
  ],

  props: {
    dialogAddCompetitorVisible: {
      type: Boolean,
      default: false
    },
    competitorAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      removeLoadingExamples: false,
      cropping: false,
      guideVisible: false,
      advantagesDefault: [
        { id: 1, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.price'), value: 0 },
        { id: 2, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.quality'), value: 0 },
        { id: 3, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.customerService'), value: 0 },
        { id: 4, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.reputation'), value: 0 },
        { id: 5, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.location'), value: 0 }
      ],
      form: {
        id: 0,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        ideaId: this.$store.state.idea.id,
        name: '',
        description: '',
        direct: true,
        advantages: [
          { id: 1, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.price'), value: 0 },
          { id: 2, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.quality'), value: 0 },
          { id: 3, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.customerService'), value: 0 },
          { id: 4, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.reputation'), value: 0 },
          { id: 5, title: this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.location'), value: 0 }
        ],
        updateOtherAdvantagesTitle: false
      },
      // TODO - This property was named advantageInputVisibleId, but there is probably some bug with Vue that says "Property or method "advantageinputvisibleid" is not defined on the instance" when it is named Camel Case.
      advantageinputvisibleid: null,
      advantageInputHover: false
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    isDialogOpen () {
      return this.dialogAddCompetitorVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getCompetitors')
    }
  },

  watch: {
    'form.name' () {
      this.form.name = this.form.name.replace(/^\s+/g, '')
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeCompetitors',
      'updateOrderNumber'
    ]),

    onStartCompetitorsOrder () {
      this.$refs.itemDropdownWrapper.forEach(dropdown => {
        dropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangeCompetitorsOrder (data) {
      this.updateOrderNumber({
        type: 'competitors',
        data,
        oldOrderData: this.competitors,
        mutation: 'setCompetitors'
      })
    },

    closeDialog () {
      this.$emit('close-dialog-add-competitor')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addCompetitorGuide')
      if (this.competitorAction) {
        this.form.id = this.competitorAction.id
        this.form.ideaId = this.$store.state.idea.id
        this.form.image = this.competitorAction.image
        this.form.name = this.competitorAction.name
        this.form.description = this.competitorAction.description
        this.form.direct = this.competitorAction.direct
        this.form.advantages = [
          { id: 1, title: this.competitorAction.advantageTitle1, value: this.competitorAction.advantageValue1 },
          { id: 2, title: this.competitorAction.advantageTitle2, value: this.competitorAction.advantageValue2 },
          { id: 3, title: this.competitorAction.advantageTitle3, value: this.competitorAction.advantageValue3 },
          { id: 4, title: this.competitorAction.advantageTitle4, value: this.competitorAction.advantageValue4 },
          { id: 5, title: this.competitorAction.advantageTitle5, value: this.competitorAction.advantageValue5 }
        ]
      } else {
        if (this.$store.state.idea.storyMode.research.competitors.competitors.length > 0) {
          this.form.advantages[0].title = this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle1
          this.form.advantages[1].title = this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle2
          this.form.advantages[2].title = this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle3
          this.form.advantages[3].title = this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle4
          this.form.advantages[4].title = this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle5
        }
      }
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        image: null,
        uploadedImage: null,
        croppedImage: null,
        name: '',
        description: '',
        direct: true,
        advantages: [
          {
            id: 1,
            title: this.$store.state.idea.storyMode.research.competitors.competitors.length ? this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle1 : this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.price'),
            value: 0
          },
          {
            id: 2,
            title: this.$store.state.idea.storyMode.research.competitors.competitors.length ? this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle2 : this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.quality'),
            value: 0
          },
          {
            id: 3,
            title: this.$store.state.idea.storyMode.research.competitors.competitors.length ? this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle3 : this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.customerService'),
            value: 0
          },
          {
            id: 4,
            title: this.$store.state.idea.storyMode.research.competitors.competitors.length ? this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle4 : this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.reputation'),
            value: 0
          },
          {
            id: 5,
            title: this.$store.state.idea.storyMode.research.competitors.competitors.length ? this.$store.state.idea.storyMode.research.competitors.competitors[0].advantageTitle5 : this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.location'),
            value: 0
          }
        ],
        updateOtherAdvantagesTitle: false
      }
      this.cropping = false
      this.$refs.avatarUploader.reset()
    },

    resetGuide () {
      if (this.$store.getters['idea/getCompetitors'].length === 0) {
        this.guideVisible = false
      }
    },

    saveCompetitor () {
      if (this.cropping) {
        this.cropImage()
      }
      this.populateEmptyAdvantageTitleWithDefault()
      this.loading = true
      if (this.form.id === 0) {
        this.addCompetitor()
      } else {
        this.updateCompetitor()
      }
    },

    addCompetitor () {
      delete this.form.uploadedImage
      this.$http.post('story-mode/research/competitors/competitors', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addCompetitor', response.data.payload.competitor)
            if (this.form.updateOtherAdvantagesTitle === true) {
              this.$store.commit('idea/updateCompetitorsAdvantagesTitles', response.data.payload.competitor)
            }
            this.resetForm()
            this.scrollToTop()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    updateCompetitor () {
      this.$http.put(`story-mode/research/competitors/competitors/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateCompetitor', response.data.payload.competitor)
            if (this.form.updateOtherAdvantagesTitle === true) {
              this.$store.commit('idea/updateCompetitorsAdvantagesTitles', response.data.payload.competitor)
            }
            this.resetForm()
            this.scrollToTop()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    onEditCompetitor (competitor) {
      this.copyCompetitorToForm(competitor)
    },

    onCopyCompetitor (competitor) {
      this.copyCompetitorToForm(competitor)
      this.form.id = 0
    },

    copyCompetitorToForm (competitor) {
      this.resetForm()
      this.form = Object.assign({}, competitor)

      const { advantageTitle1, advantageTitle2, advantageTitle3, advantageTitle4, advantageTitle5, advantageValue1, advantageValue2, advantageValue3, advantageValue4, advantageValue5, ...rest } = this.form
      this.form = rest

      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'uploadedImage', null)
      this.$set(this.form, 'croppedImage', null)
      this.$set(this.form, 'updateOtherAdvantagesTitle', false)
      this.$set(this.form, 'advantages', {})
      this.form.advantages = [
        { id: 1, title: competitor.advantageTitle1, value: competitor.advantageValue1 },
        { id: 2, title: competitor.advantageTitle2, value: competitor.advantageValue2 },
        { id: 3, title: competitor.advantageTitle3, value: competitor.advantageValue3 },
        { id: 4, title: competitor.advantageTitle4, value: competitor.advantageValue4 },
        { id: 5, title: competitor.advantageTitle5, value: competitor.advantageValue5 }
      ]
      this.scrollToTop()
    },

    deleteImage () {
      this.form.image = null
      this.form.uploadedImage = null
      this.form.croppedImage = null
    },

    cropImage () {
      this.cropping = false
      const image = this.$refs.avatarUploader.cropImage()
      if (image) {
        this.form.croppedImage = image.croppedImage
        this.form.uploadedImage = image.uploadedImage

        return
      }
      this.deleteImage()
    },

    cardImageBySize (image, size) {
      return image ? 'background-image: url(' + getImageBySize(image.sizes, size) + ')' : ''
    },

    calculateAdvantageStrength (advantage) {
      if (advantage.value <= 30) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.poor')
      } else if (advantage.value <= 60) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.fair')
      } else if (advantage.value <= 90) {
        return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.good')
      }

      return this.$t('pages.businessGuide.competitors.addDialog.competitiveAdvantages.excellent')
    },

    activateAdvantageInput (advantage) {
      this.advantageinputvisibleid = advantage.id
      this.$nextTick(() => this.$refs['advantageInput' + advantage.id][0].focus())
    },

    getCompetitorLevel (competitor) {
      // noinspection JSUnresolvedVariable
      const advantageLevel = competitor.advantageValue1 + competitor.advantageValue2 + competitor.advantageValue3 +
          competitor.advantageValue4 + competitor.advantageValue5

      if (advantageLevel <= 200) {
        return this.$t('low')
      } else if (advantageLevel <= 400) {
        return this.$t('medium')
      }

      return this.$t('high')
    },

    populateEmptyAdvantageTitleWithDefault () {
      for (let i = 0; i < this.form.advantages.length; i++) {
        if (!this.form.advantages[i].title) {
          this.form.advantages[i].title = this.advantagesDefault[i].title
        }
      }
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('competitors')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
