<template>
  <div>
    <ib-divider block class="my-3" />

    <div class="mt-5 d-flex justify-end">
      <ib-button
        v-if="!isFirstStep || (!firstTime && getIdeasCount > 0)"
        class="mr-2"
        submit-button
        variant="white"
        :disabled="loading"
        font-family="medium"
        @click="(isFirstStep) ? $emit('back-to-app') : $emit('back')"
      >
        {{ $t('back') }}
      </ib-button>
      <ib-button
        variant="blue"
        font-family="semi-bold"
        submit-button
        :loading="loading"
        :disabled="!validation"
        @click="isLastStep ? $emit('finish') : $emit('next')"
      >
        {{ nextBtnText }}
      </ib-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OnboardingWizardFooter',

  inject: ['getFirstTime', 'getCurrentStep'],

  props: {
    validation: {
      type: Boolean,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    },

    isFirstStep: {
      type: Boolean,
      required: true
    },

    isLastStep: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('ideaCollection', ['getIdeasCount']),

    firstTime () {
      return this.getFirstTime()
    },

    currentStep () {
      return this.getCurrentStep()
    },

    nextBtnText () {
      if (this.isLastStep) return this.$t('finish')

      if (this.currentStep === 1) {
        return this.firstTime ? this.$t('pages.onBoarding.okLetsGo') : this.$t('continue')
      }

      return this.$t('next')
    }
  }
}
</script>
