<template>
  <div id="journal">
    <div class="container-1200">
      <div class="content journal-inner">

        <!-- Header -->
        <header class="page-header">

          <dashboard-header
            :title="$t('sidebar.businessPlan')"
            :quick-tour-visible="quickTourVisible"
            hide-guide-button
            @toggle-quick-tour="toggleQuickTour"
          >
            <template #actions>
              <div class="d-flex">

                <div class="export-share-button d-flex justify-content-center align-items-center">
                  <a v-if="!canExportShare" class="d-flex align-items-center" @click="showUpgradeDialog">
                    <i class="ib-icon-download h3 text-silver " />
                    <span class="d-none d-lg-flex ml-2 text-silver font-outfit-regular">{{ $t('export') }}</span>
                  </a>
                  <!-- Download PDF -->
                  <el-dropdown v-else trigger="click" @command="generateDocument">
                    <a class="d-flex align-items-center el-dropdown-link print" @click.prevent>
                      <i class="ib-icon-download h3 text-silver " />
                      <span class="d-none d-lg-flex ml-2 text-silver font-outfit-regular">{{ $t('export') }}</span>
                    </a>
                    <el-dropdown-menu slot="dropdown" class="export-dropdown-menu">
                      <el-dropdown-item
                        class="d-flex"
                        icon="ib-icon-export-pdf"
                        command="pdf"
                      >
                        {{ $t('exportToPdf') }}
                      </el-dropdown-item>
                      <el-dropdown-item
                        class="d-flex"
                        icon="ib-icon-export-word"
                        command="doc"
                      >
                        {{ $t('exportToWord') }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
                <!-- /Download PDF -->

                <!-- Share -->
                <div class="export-share-button d-flex justify-content-center align-items-center ml-3">
                  <a class="d-flex align-items-center" @click="shareJournal">
                    <i v-if="shareLoading" class="el-icon-loading" />
                    <i v-if="!shareLoading" class="ib-icon-share h3 text-silver" />
                    <span class="d-none d-lg-flex ml-2 text-silver font-outfit-regular">{{ $t('share') }}</span>
                  </a>
                </div>
                <!-- /Share -->

              </div>
              <!-- /Download and Share Buttons -->
            </template>
          </dashboard-header>

          <hr>
        </header>
        <!-- /Header -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Quick tour -->
        <quick-tour-card-wrapper
          :active="quickTourVisible"
          closable
          @close="onCloseQuickTour"
        >
          <business-plan-quick-tour />
        </quick-tour-card-wrapper>
        <!-- /Quick tour -->

        <!-- Completed step banner -->
        <!--        <completed-step-banner v-if="isAllNotCompleted && isPageContentVisible" />-->
        <!-- /Completed step banner -->

        <div
          v-if="sidebarSmallScreen && isPageContentVisible"
          class="d-flex justify-content-end mb-3"
        >
          <ib-tooltip
            popper-class="medium center"
            class="ml-4"
            :content="$t('pages.businessPlan.addNewPage')"
          >
            <el-button
              v-if="canEdit"
              :class="{'d-none': !sidebarSmallScreen}"
              :disabled="addButtonDisabled"
              class="width-fix add-new-button"
              type="plus-round-xs"
              icon="el-icon-plus"
              @click="addCustomPage"
            />
          </ib-tooltip>
          <el-button
            icon="el-icon-s-fold"
            class="p-3"
            @click.stop="clickOnMenuBtn"
          >
            {{ $t('pages.businessPlan.outline') }}
          </el-button>
        </div>

        <!-- Journal / Business Plan Section Content -->
        <div v-if="isPageContentVisible" ref="journal-flex" class="journal-flex">
          <div ref="journal-wrapper" class="journal-content-wrapper">
            <!-- Journal StoryMode and Dynamically Generated Section (Pages) -->

            <!-- Components from Story Mode -->
            <template v-for="(componentEl, index) in businessPlanSections">
              <loader v-if="!loaded[lcfirst(componentEl.sectionName)]" :key="index" />
              <template v-if="loaded[lcfirst(componentEl.sectionName)]">
                <component
                  :is="componentEl.sectionName"
                  v-if="componentEl.sectionName"
                  :id="componentEl.id"
                  :key="componentEl.id"
                  :all-pages-text-data="componentEl"
                  @save="onSaveJournalSection"
                  @delete="onDeleteJournalSection($event)"
                />

                <div v-else-if="isOption(componentEl, 'switch')" :key="'dynamically' + index" class="journal-content generated-section">
                  <!-- Page Menu -->
                  <div v-if="canEdit" class="content-menus">
                    <div class="mini-actions">
                      <a class="edit" @click="editNewJournalSection(componentEl)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#fff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
                      </a>
                      <a class="delete" @click="deleteJournalSection(componentEl.id)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M15 4h4a1 1 0 110 2h-1v13a3 3 0 01-3 3H5a3 3 0 01-3-3V6H1a1 1 0 110-2h4V3a3 3 0 013-3h4a3 3 0 013 3v1zm-2 0V3a1 1 0 00-1-1H8a1 1 0 00-1 1v1h6zm3 2H4v13a1 1 0 001 1h10a1 1 0 001-1V6zm-9 4a1 1 0 112 0v6a1 1 0 11-2 0v-6zm4 0a1 1 0 112 0v6a1 1 0 11-2 0v-6z"/></svg>
                      </a>
                    </div>
                  </div>
                  <!-- /Page Menu -->

                  <div class="sub chapter">
                    <header>
                      <h2>{{ componentEl.title }}</h2>
                      <hr>
                    </header>

                    <div
                      class="content-text"
                      v-html="componentEl.subTitle"
                    />
                  </div>
                </div>
              </template>
            </template>
            <!-- /Components from Story Mode -->
            <!--            </template>-->
            <!-- /Journal StoryMode and Dynamically Generated  Section (Pages) -->
          </div>

          <!-- Journal SideBar -->
          <div
            v-on-clickaway="awaySidebar"
            class="journal-sidebar"
            :class="{'sidebar-small': sidebarSmallScreen, 'active': openSidebar}"
          >

            <header>
              <div class="flex">
                <h2>{{ $t('pages.businessPlan.outline') }}</h2>
                <ib-tooltip
                  popper-class="medium center"
                  class="ml-4"
                  :content="$t('pages.businessPlan.addNewPage')"
                >
                  <el-button
                    v-if="canEdit"
                    :class="{'d-none': sidebarSmallScreen}"
                    :disabled="addButtonDisabled"
                    type="plus-round-xs"
                    icon="el-icon-plus"
                    class="add-new-button"
                    @click="addCustomPage"
                  />
                </ib-tooltip>
              </div>
              <hr>
            </header>

            <div class="chapters">
              <draggable
                v-model="businessPlanSectionsFormated"
                class="list-group"
                handle=".drag-handle-single"
                animation="200"
                v-bind="dragOptions"
                :move="checkMove"
                @start="isDragging = true"
                @end="isDragging = false"
                @change="changeOrder"
              >
                <!--                businessPlanSectionsFormated-->
                <!-- Switch Bar -->
                <template v-for="(element, index) in businessPlanSectionsFormated">
                  <template v-if="element.id === 'section'">
                    <p :key="index">
                      {{ element.title }}
                      <i v-if="subscriptionIsFreeTrial && element.paid" class="ib-icon-lock-2 o-50" />
                    </p>
                  </template>
                  <template v-else>
                    <template v-if="getIsBusinessGuideSectionFinished(element.sectionName) === false && getBusinessGuideSectionRoute(element.sectionName)">
                      <component
                        :is="subscriptionIsFreeTrial && (index >= 7 && index <= 24) ? 'div' : 'router-link'"
                        v-if="(element.id) || element.sectionName === 'Custom'"
                        :key="'switchbar' + element.id"
                        class="accordion-item drag-handle-single left"
                        :class="{
                          'inactive': getIsBusinessGuideSectionFinished(element.sectionName) === false,
                          'd-none': subscriptionIsFreeTrial && (index >= 7 && index <= 24)
                        }"
                        :to="{name: getBusinessGuideSectionRoute(element.sectionName)}"
                        @click="setDialogUpgradeState(true)"
                      >
                        <!--                          'disabled': index >= 7 && index <= 24,-->
                        <div class="left">
                          <i class="ib-icon-plus-math mr-2 text-silver" />
                          <div class="editable-title flex-column">
                            {{ element.title }}
                          </div>
                        </div>
                      </component>
                    </template>
                    <template v-else>
                      <div
                        v-if="(element.id) || element.sectionName === 'Custom'"
                        :key="'switchbar' + element.id"
                        :class="{
                          'inactive': subscriptionIsFreeTrial && (index >= 7 && index <= 24),
                          'd-none': subscriptionIsFreeTrial && (index >= 7 && index <= 24)
                        }"
                        class="accordion-item drag-handle-single"
                        @click="subscriptionIsFreeTrial && (index >= 7 && index <= 24) ? setDialogUpgradeState(true) : null"
                      >
                        <!--                          'disabled': subscriptionIsFreeTrial && index >= 7 && index <= 24,-->
                        <!-- Left -->
                        <div class="left">
                          <i
                            v-if="element.sectionName !== 'Cover'"
                            class="mr-2 text-silver cursor-move ib-icon-drag_icon"
                            :class="{'d-none': subscriptionIsFreeTrial && index >= 26, 'ib-icon-plus-math': subscriptionIsFreeTrial && (index >= 7 && index <= 24)}"
                          />
                          <div class="editable-title flex-column cursor-pointer" @click="scrollToPage(element.id)">
                            {{ element.title }}
                          </div>
                        </div>
                        <!-- /Left -->

                        <template v-if="getIsBusinessGuideSectionFinished(element.sectionName) !== false">

                          <!-- Tooltip and Switch -->
                          <ib-tooltip
                            v-if="subscriptionIsFreeTrial && !(index >= 7 && index <= 24) || !subscriptionIsFreeTrial"
                            placement="top"
                            popper-class="plan-popper"
                          >
                            <!-- Tooltip Content -->
                            <div slot="content" class="tooltip-content">
                              <p>{{ $t('includeExcludeThisSection') }}</p>
                            </div>
                            <!-- /Tooltip Content -->
                            <i
                              class="cursor-pointer o-50"
                              :class="isOption(element, 'switch') ? 'ib-icon-eye' : 'ib-icon-hide'"
                              @click="onSaveJournalSection(element, 'switch', true)"
                            />

                            <!-- Switch Section -->
                            <!--                            <el-switch-->
                            <!--                              v-if="canEdit"-->
                            <!--                              class="journal-switch"-->
                            <!--                              :value="isOption(element, 'switch')"-->
                            <!--                              active-color="#ffba00"-->
                            <!--                              inactive-color="#d2d2d2"-->
                            <!--                              @change="onSaveJournalSection(element, 'switch')"-->
                            <!--                            />-->
                            <!-- /Switch Section -->

                          </ib-tooltip>
                          <!-- /Tooltip and Switch -->
                        </template>

                      </div>
                    </template>

                  </template>

                </template>
                <!-- /Switch Bar -->
              </draggable>
            </div>
          </div>
          <!-- /Journal / Business Plan SideBar -->

        </div>
        <!-- Journal Section Content -->

        <!-- Business Plan Video Quick Tour -->
        <dialog-business-plan-quick-tour
          :visible="dialogQuickTourVisible"
          @skip="onSkipQuickTour"
        />
        <!-- /Business Plan Video Quick Tour -->

        <!-- Dialog Add New Journal Section -->
        <dialog-add-new-section
          :dialog-add-new-section-visible="dialogAddNewSectionVisible"
          :page-data="newPageData"
          @on-save="onSaveNewJournalSection"
          @on-edit="onUpdateJournalSection"
          @on-close-dialog="closeJournalDialog"
        />
        <!-- /Dialog Add New Journal Section -->

        <!-- Dialog Share Financial Plan -->
        <dialog-share
          :title="$t('dialogs.share.shareBusinessPlan')"
          type="businessPlan"
          :visible="dialogShareJournalVisible"
          :token="sharedJournalToken"
          @close="onCloseShareJournal"
        />
        <!-- /Dialog Share Financial Plan -->

        <!-- Dialog Export/Generate -->
        <dialog-generate
          api="/story-mode/journal/generate"
          :format="generateFormat"
          :dialog-visible="generateDialog"
          @close="generateDialog = false"
        />
        <!-- /Dialog Export/Generate -->

        <!--<dialog-edit-section />-->
      </div>
    </div>
  </div>
</template>

<script>
// import DialogEditSection from './Dialogs/DialogEditSection'
// import SetUpMonthlyTable from './../SetUp/StartupCosts/Tables/StartupCostsTable'
// import VueApexCharts from 'vue-apexcharts'
import BusinessPlanQuickTour from '@/views/Home/Components/QuickTours/BusinessPlanQuickTour'
import Competitors from '@/views/Home/StoryMode/Journal/Pages/Competitors'
import CompletedStepBanner from '@/views/Home/StoryMode/Components/BusinessPlan/CompletedStepBanner'
import Cover from '@/views/Home/StoryMode/Journal/Pages/Cover'
import Custom from '@/views/Home/StoryMode/Journal/Pages/Custom'
import Customers from '@/views/Home/StoryMode/Journal/Pages/Customers'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddNewSection from './Dialogs/DialogAddNewSection'
import DialogBusinessPlanQuickTour from '@/views/Home/Components/Dialogs/DialogBusinessPlanQuickTour'
import DialogGenerate from '@/views/Home/StoryMode/Components/Dialogs/DialogGenerate'
import DialogShare from '@/views/Home/Components/Dialogs/DialogShare'
import DirectCosts from '@/views/Home/StoryMode/Journal/Pages/DirectCosts'
import Distribution from '@/views/Home/StoryMode/Journal/Pages/Distribution'
import Draggable from 'vuedraggable'
import Financing from '@/views/Home/StoryMode/Journal/Pages/Financing'
import IbQuickTourButton from '@/components/_v2/Button/IbQuickTourButton'
import Idea from '@/views/Home/StoryMode/Journal/Pages/Idea'
import Loader from '@/components/Loader'
import ManagementTeam from '@/views/Home/StoryMode/Journal/Pages/ManagementTeam'
import Market from '@/views/Home/StoryMode/Journal/Pages/Market'
import MarketPotential from '@/views/Home/StoryMode/Journal/Pages/MarketPotential'
import MarketingBudget from '@/views/Home/StoryMode/Journal/Pages/MarketingBudget'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCountries from '@/mixins/countries'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinStoryModeSteps from '@/mixins/storyModeSteps'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import OtherOverheads from '@/views/Home/StoryMode/Journal/Pages/OtherOverheads'
import Ownership from '@/views/Home/StoryMode/Journal/Pages/Ownership'
import Partner from '@/views/Home/StoryMode/Journal/Pages/Partner'
import Product from '@/views/Home/StoryMode/Journal/Pages/Product'
import ProfitAndCashFlow from '@/views/Home/StoryMode/Journal/Pages/ProfitAndCashFlow'
import Promotion from '@/views/Home/StoryMode/Journal/Pages/Promotion'
import QuickTourCardWrapper from '@/views/Home/Components/QuickTours/QuickTourCardWrapper'
import RevenueStreams from '@/views/Home/StoryMode/Journal/Pages/RevenueStreams'
import StaffAndPayroll from '@/views/Home/StoryMode/Journal/Pages/StaffAndPayroll'
import StartupCosts from '@/views/Home/StoryMode/Journal/Pages/StartupCosts'
import SwotAnalysis from '@/views/Home/StoryMode/Journal/Pages/SwotAnalysis'
import axios from 'axios'
import journalMethods from '@/mixins/journalMethods'

import _cloneDeep from 'lodash/cloneDeep'
import _last from 'lodash/last'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { directive as onClickaway } from 'vue-clickaway'

const CancelToken = axios.CancelToken
let source = null

export default {
  name: 'BusinessPlan',

  directives: {
    onClickaway: onClickaway
  },

  components: {
    // DialogEditSection
    // SetUpMonthlyTable
    // VueApexCharts,
    BusinessPlanQuickTour,
    Competitors,
    CompletedStepBanner,
    Cover,
    Custom,
    Customers,
    DashboardHeader,
    DialogAddNewSection,
    DialogBusinessPlanQuickTour,
    DialogGenerate,
    DialogShare,
    DirectCosts,
    Distribution,
    Draggable,
    Financing,
    IbQuickTourButton,
    Idea,
    Loader,
    ManagementTeam,
    Market,
    MarketPotential,
    MarketingBudget,
    OtherOverheads,
    Ownership,
    Partner,
    Product,
    ProfitAndCashFlow,
    Promotion,
    QuickTourCardWrapper,
    RevenueStreams,
    StaffAndPayroll,
    StartupCosts,
    SwotAnalysis
  },

  mixins: [
    MixinCountries,
    MixinStoryModeSteps,
    journalMethods,
    MixinApiCalculations,
    MixinIdeaRoles,
    MixinUserSubscription,
    MixinGuide
  ],

  props: {
    completedStepName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      businessPlanSectionsFormated: [],
      quickTourVisible: false,
      isPageContentVisible: true,
      dialogQuickTourVisible: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      allPagesTextData: {},
      newPageData: null,
      addButtonDisabled: false,

      dialogAddNewSectionVisible: false,
      // dialogEditSectionVisible: false,

      activeNames: ['1', '2', '3', '4', '5', '6'],

      dragging: false,
      moreVisible: false,

      breakdownSection: true,
      timelineSection: true,
      tableSection: true,
      inputHover: true,

      competitorTable: true,
      productsTable: true,
      promotionTable: true,
      membersTable: true,
      customersTable: true,

      whyCard: true,
      whatCard: true,
      coverageCard: true,
      growthCard: true,
      sidebarTop: '',
      sidebarSmallScreen: false,
      openSidebar: false,

      addedJournalSections: [],
      journalPageList: [],

      dialogShareJournalVisible: false,
      shareJournalAction: null,
      sharedJournalToken: '',
      completed: {
        '': true,
        Cover: true,
        Idea: this.$store.state.idea.storyMode.common.completedSteps.ideaCompleted,
        Product: this.$store.state.idea.storyMode.common.completedSteps.productCompleted,
        Promotion: this.$store.state.idea.storyMode.common.completedSteps.promotionCompleted,
        Distribution: this.$store.state.idea.storyMode.common.completedSteps.distributionCompleted,
        Partner: this.$store.state.idea.storyMode.common.completedSteps.partnerCompleted,
        Market: this.$store.state.idea.storyMode.common.completedSteps.marketCompleted,
        Customers: this.$store.state.idea.storyMode.common.completedSteps.customersCompleted,
        Competitors: this.$store.state.idea.storyMode.common.completedSteps.competitorsCompleted,
        SwotAnalysis: this.$store.state.idea.storyMode.common.completedSteps.swotAnalysisCompleted,
        MarketPotential: this.$store.state.idea.storyMode.common.completedSteps.marketPotentialCompleted,
        StartupCosts: this.$store.state.idea.storyMode.common.completedSteps.startupCostsCompleted,
        Financing: this.$store.state.idea.storyMode.common.completedSteps.financingCompleted,
        ManagementTeam: this.$store.state.idea.storyMode.common.completedSteps.managementTeamCompleted,
        StaffAndPayroll: this.$store.state.idea.storyMode.common.completedSteps.employeesAndPayrollCompleted,
        Ownership: this.$store.state.idea.storyMode.common.completedSteps.ownershipCompleted,
        RevenueStreams: this.$store.state.idea.storyMode.common.completedSteps.revenueStreamsCompleted,
        DirectCosts: this.$store.state.idea.storyMode.common.completedSteps.directCostsCompleted,
        MarketingBudget: this.$store.state.idea.storyMode.common.completedSteps.marketingBudgetCompleted,
        OtherOverheads: this.$store.state.idea.storyMode.common.completedSteps.otherOverheadsCompleted,
        ProfitAndCashFlow: this.$store.state.idea.storyMode.common.completedSteps.profitAndCashFlowCompleted
      },
      isDragging: false,
      shareLoading: false,
      generateDialog: false,
      generateFormat: '',
      loaded: {
        custom: true,
        cover: true,
        idea: true,
        product: true,
        promotion: true,
        distribution: true,
        partner: true,
        market: true,
        customers: true,
        competitors: true,
        swotAnalysis: true,
        marketPotential: true,
        managementTeam: true,
        staffAndPayroll: false,
        ownership: true,
        startupCosts: false,
        financing: false,
        revenueStreams: false,
        directCosts: false,
        marketingBudget: false,
        otherOverheads: false,
        profitAndCashFlow: false
      }
    }
  },

  computed: {
    ...mapState({
      idea: state => state.idea
    }),

    ...mapState({
      user: state => state.user
    }),

    ...mapGetters('idea', [
      'getBusinessPlanSections',
      'getStepIsCompleted'
    ]),

    ...mapGetters('user', [
      'isQuickTourCompleted'
    ]),

    isAllNotCompleted () {
      return !Object.keys(this.completed).filter(i => this.completed[i] && i !== '' && i !== 'Cover').length
    },

    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.canEdit,
        ghostClass: 'ghost',
        delay: 100,
        delayOnTouchOnly: true
      }
    },

    businessPlanSections () {
      return this.getBusinessPlanSections
    },

    canExportShare () {
      return !this.subscriptionIsDreamer && !this.subscriptionIsFreeTrial
    }
  },

  created () {
    if (this.subscriptionIsFreeTrial || this.subscriptionIsDreamer) {
      this.dialogQuickTourVisible = true
    }

    if (!this.subscriptionIsFreeTrial && !this.subscriptionIsDreamer) {
      this.openQuickTour('businessPlanQuickTour')
    }

    window.addEventListener('resize', this.windowResized)

    const listOfElem = [
      '.customer-card .top-segment h3',
      '.customer-card .top-segment .desc',
      '.customer-card .top-segment .pain-points',
      '.competitor-card .top-segment h3',
      '.competitor-card .top-segment .desc',
      '.promotion-card .card-bottom h3',
      '.distribution-card .card-bottom h3',
      '.distribution-card .card-bottom .desc',
      '.partner-card .card-bottom h3',
      '.partner-card .card-bottom .desc',
      '.product-card .card-bottom h3',
      '.product-card .card-bottom .category',
      '.member-card .card-bottom h3',
      '.member-card .card-bottom .position'
    ]

    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        for (let i = 0; i < listOfElem.length; i++) {
          const elemGroup = document.querySelectorAll(listOfElem[i])
          for (let k = 0; k < elemGroup.length; k++) {
            elemGroup[k].style.removeProperty('height')
          }
        }

        this.setElementHeight(listOfElem)
      }
    }
  },

  destroyed () {
    window.removeEventListener('resize', this.windowResized)
  },

  mounted () {
    this.$nextTick(() => {
      if (this.$route.query.section) {
        this.scrollToPage(this.$route.query.section)
      }
      this.setElementHeight([
        '.customer-card .top-segment h3',
        '.customer-card .top-segment .desc',
        '.customer-card .top-segment .pain-points',
        '.competitor-card .top-segment h3',
        '.competitor-card .top-segment .desc',
        '.promotion-card .card-bottom h3',
        '.distribution-card .card-bottom h3',
        '.distribution-card .card-bottom .desc',
        '.partner-card .card-bottom h3',
        '.partner-card .card-bottom .desc',
        '.product-card .card-bottom h3',
        '.product-card .card-bottom .category',
        '.member-card .card-bottom h3',
        '.member-card .card-bottom .position'
      ])
    })

    this.formatBusinessPlanSections()

    this.windowResized()

    this.loaded.profitAndCashFlow = Object.keys(this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation).length !== 0
    if (!this.loaded.profitAndCashFlow) {
      this.getProfitAndCashFlow()
    }

    this.loaded.startupCosts = Object.keys(this.$store.state.idea.storyMode.setUp.startupCosts.breakdown).length !== 0
    if (!this.loaded.startupCosts) {
      this.getStartupCosts()
    }

    this.loaded.staffAndPayroll = Object.keys(this.$store.state.idea.storyMode.setUp.staffAndPayrolls.breakdown).length !== 0
    if (!this.loaded.staffAndPayroll) {
      this.getStaffAndPayroll()
    }

    this.loaded.financing = Object.keys(this.$store.state.idea.storyMode.setUp.financing.breakdown).length !== 0
    if (!this.loaded.financing) {
      this.getFinancing()
    }

    this.loaded.revenueStreams = Object.keys(this.$store.state.idea.storyMode.forecast.revenueStreams.breakdown).length !== 0
    if (!this.loaded.revenueStreams) {
      this.getRevenueStreams()
    }

    this.loaded.directCosts = Object.keys(this.$store.state.idea.storyMode.forecast.directCosts.breakdown).length !== 0
    if (!this.loaded.directCosts) {
      this.getDirectCosts()
    }

    this.loaded.marketingBudget = Object.keys(this.$store.state.idea.storyMode.forecast.marketingBudget.breakdown).length !== 0
    if (!this.loaded.marketingBudget) {
      this.getMarketingBudget()
    }

    this.loaded.otherOverheads = Object.keys(this.$store.state.idea.storyMode.forecast.otherOverheads.breakdown).length !== 0
    if (!this.loaded.otherOverheads) {
      this.getOtherOverheads()
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteJournalSection'
    ]),

    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),

    formatBusinessPlanSections () {
      const items = _cloneDeep(this.getBusinessPlanSections)

      const sections = [
        { title: this.$t('steps.concept.title'), id: 'section', paid: false },
        { title: this.$t('steps.research.title'), id: 'section', paid: true },
        { title: this.$t('steps.setUp.title'), id: 'section', paid: true },
        { title: this.$t('steps.projections.title'), id: 'section', paid: true },
        { title: this.$t('other'), id: 'section', paid: false }
      ]

      let j = 0
      for (let i = 1; i < items.length; i += 5) {
        if (sections[j]) {
          items.splice(i, 0, sections[j])
        }
        i++
        j++
      }

      this.businessPlanSectionsFormated = items
    },

    async onDeleteJournalSection (event) {
      await this.deleteJournalSection(event)
      this.formatBusinessPlanSections()
    },

    getBusinessGuideSectionRoute (section) {
      const routes = {
        Idea: 'business-guide-concept-idea',
        Product: 'business-guide-concept-product',
        Promotion: 'business-guide-concept-promotion',
        Distribution: 'business-guide-concept-distribution',
        Partner: 'business-guide-concept-partners',
        Market: 'business-guide-research-market',
        Customers: 'business-guide-research-customers',
        Competitors: 'business-guide-research-competitors',
        SwotAnalysis: 'business-guide-research-swot-analysis',
        MarketPotential: 'business-guide-research-market-potential',
        StartupCosts: 'business-guide-set-up-startup-costs',
        Financing: 'business-guide-set-up-financing',
        ManagementTeam: 'business-guide-set-up-management-team',
        StaffAndPayroll: 'business-guide-set-up-staff-and-payroll',
        Ownership: 'business-guide-set-up-ownership',
        RevenueStreams: 'business-guide-projections-revenue-streams',
        DirectCosts: 'business-guide-projections-direct-costs',
        MarketingBudget: 'business-guide-projections-marketing-budget',
        OtherOverheads: 'business-guide-projections-other-overheads',
        ProfitAndCashFlow: 'business-guide-projections-profit-and-cash-flow'
      }

      return routes[section] ? routes[section] : null
    },

    getIsBusinessGuideSectionFinished (section) {
      const items = {
        Idea: this.getStepIsCompleted('ideaCompleted'),
        Product: this.getStepIsCompleted('productCompleted'),
        Promotion: this.getStepIsCompleted('promotionCompleted'),
        Distribution: this.getStepIsCompleted('distributionCompleted'),
        Partner: this.getStepIsCompleted('partnerCompleted'),
        Market: this.getStepIsCompleted('marketCompleted'),
        Customers: this.getStepIsCompleted('customersCompleted'),
        Competitors: this.getStepIsCompleted('competitorsCompleted'),
        SwotAnalysis: this.getStepIsCompleted('swotAnalysisCompleted'),
        MarketPotential: this.getStepIsCompleted('marketPotentialCompleted'),
        StartupCosts: this.getStepIsCompleted('startupCostsCompleted'),
        Financing: this.getStepIsCompleted('financingCompleted'),
        ManagementTeam: this.getStepIsCompleted('managementTeamCompleted'),
        StaffAndPayroll: this.getStepIsCompleted('employeesAndPayrollCompleted'),
        Ownership: this.getStepIsCompleted('ownershipCompleted'),
        RevenueStreams: this.getStepIsCompleted('revenueStreamsCompleted'),
        DirectCosts: this.getStepIsCompleted('directCostsCompleted'),
        MarketingBudget: this.getStepIsCompleted('marketingBudgetCompleted'),
        OtherOverheads: this.getStepIsCompleted('otherOverheadsCompleted'),
        ProfitAndCashFlow: this.getStepIsCompleted('profitAndCashFlowCompleted')
      }

      return typeof items[section] !== 'undefined' ? items[section] : null
    },

    async addCustomPage () {
      this.addButtonDisabled = true
      this.$store.commit('idea/addJournalSection', {
        sectionName: 'Custom',
        title: this.getCustomPageTitle(),
        subTitle: '',
        orderNumber: this.getBusinessPlanSections.length + 1,
        moreDetailsTitle: ''
      })

      const response = await this.save(this.businessPlanSections)
      this.formatBusinessPlanSections()
      this.scrollToPage(_last(response.data.payload.journal.journalSwitchBars).id)
    },

    getCustomPageTitle () {
      // ToDo Translate
      const defaultPageTitle = 'New Page'
      const customSectionName = 'Custom'
      const customPages = this.businessPlanSections.filter(section => section.sectionName === customSectionName)

      if (customPages.filter(section => section.title === defaultPageTitle).length === 0) {
        return defaultPageTitle
      }

      let i = 1
      let newTitle = null

      do {
        if (customPages.filter(section => section.title === `${defaultPageTitle} (${i})`).length === 0) {
          newTitle = `${defaultPageTitle} (${i})`
        }

        i = i + 1
      } while (newTitle === null)

      return newTitle
    },

    generateDocument (format) {
      if (!this.canExportShare) return
      this.generateFormat = format
      this.generateDialog = true
    },

    setElementHeight (elemName) {
      if (Array.isArray(elemName)) {
        for (let m = 0; m < elemName.length; m++) {
          this.setElementHeightFunctionality(elemName[m])
        }
      } else {
        this.setElementHeightFunctionality(elemName)
      }
    },

    setElementHeightFunctionality (el) {
      const elem = document.querySelectorAll(el)
      let elemHeight = []
      let elemPosition = []

      for (let i = 0; i < elem.length; i++) {
        const elH = elem[i].offsetHeight
        elemHeight.push(elH)
        elemPosition.push(i)
        const largestValue = Math.max(...elemHeight)

        if ((i + 1) % 4 === 0 && i !== 0) {
          for (let k = 0; k < elemPosition.length; k++) {
            elem[elemPosition[k]].style.height = largestValue + 'px'
          }
          elemPosition = []
          elemHeight = []
        } else if (elem.length - (i + 1) < 4 && elem.length - 1 === i) {
          for (let k = 0; k < elemPosition.length; k++) {
            elem[elemPosition[k]].style.height = largestValue + 'px'
          }
          elemPosition = []
          elemHeight = []
        }
      }
    },

    lcfirst (value) {
      return value.charAt(0).toLowerCase() + value.substr(1)
    },

    onCloseShareJournal () {
      this.dialogShareJournalVisible = false
      this.shareJournalAction = null
    },

    async shareJournal () {
      if (!this.canExportShare) {
        this.showUpgradeDialog()

        return
      }

      this.shareLoading = true

      try {
        await this.getSharedJournalToken()
        this.dialogShareJournalVisible = true
        this.shareLoading = false
      } catch (e) {
        this.shareLoading = false
      }
    },

    async getSharedJournalToken () {
      if (!this.sharedJournalToken) {
        const response = await this.$http.post('story-mode/journal/share', { ideaId: this.$store.state.idea.id })
        this.sharedJournalToken = response.data.payload.sharedJournal.token
      }

      return this.sharedJournalToken
    },

    async onSaveJournalSection (element, value, showHide = false) {
      if (value !== undefined) {
        this.toggleOption(element, value)
      }

      if (showHide) {
        this.formatBusinessPlanSections()
        await this.save(this.businessPlanSectionsFormated.filter(item => item.id !== 'section'))

        return
      }

      this.$store.commit('idea/updateJournalSwitchSidebarElement', element)
      await this.save(this.businessPlanSections)
      this.formatBusinessPlanSections()
    },

    onSaveNewJournalSection (data) {
      const newPageData = {
        sectionName: '',
        title: data.title,
        subTitle: data.subTitle,
        moreDetailsTitle: ''
      }

      this.save([newPageData, ...this.businessPlanSections])
    },

    onUpdateJournalSection (data) {
      const newPageData = {
        id: data.id,
        title: data.title,
        subTitle: data.subTitle,
        sectionName: '',
        moreDetailsTitle: '',
        options: data.options
      }

      this.$store.commit('idea/updateJournalSwitchSidebarElement', newPageData)

      this.save(this.businessPlanSections)
    },

    async save (journalSwitchBars) {
      if (source) {
        await source.cancel()
      }
      source = CancelToken.source()

      const response = await this.$http.put('story-mode/journal/sidebar',
        { journalElementsList: journalSwitchBars },
        { cancelToken: source.token }
      )
      this.addButtonDisabled = false

      if (response.status === 200) {
        this.$store.commit('idea/setJournalSwitchSidebarElements', response.data.payload.journal.journalSwitchBars)
        this.closeJournalDialog()
      }

      return response
    },

    showUpgradeDialog () {
      this.setDialogUpgradeState(true)
    },

    editNewJournalSection (page) {
      this.dialogAddNewSectionVisible = true
      this.newPageData = Object.assign({}, page)
    },

    closeJournalDialog () {
      this.dialogAddNewSectionVisible = false
      this.newPageData = null
    },

    windowResized () {
      this.openSidebar = false
      this.sidebarSmallScreen = window.innerWidth < 1440

      this.resizePages()
    },

    /**
     * Scale Business Plan pages based on device width
     * Pages will be zoomed out on smalled devices
     */
    resizePages () {
      const fixedWrapperWidth = 840
      const wrapperFlexRef = this.$refs['journal-flex']
      if (!wrapperFlexRef) {
        return
      }

      const wrapperRef = this.$refs['journal-wrapper']
      const wrapperWidth = wrapperFlexRef.offsetWidth
      const calculatedScale = wrapperWidth / fixedWrapperWidth
      wrapperRef.style.transform = `scale(${calculatedScale <= 1 ? calculatedScale : 1})`

      // Fix wrapper height because `scale` keeps original space of wrapper and leaves empty space
      setTimeout(() => {
        wrapperFlexRef.style.height = 'auto'

        if (calculatedScale < 1) {
          const wrapperOffsetHeight = wrapperFlexRef.offsetHeight
          const calculatedHeight = (wrapperOffsetHeight * calculatedScale) + 20

          wrapperFlexRef.style.height = `${calculatedHeight}px`
        }
      }, 100)
    },

    clickOnMenuBtn () {
      this.openSidebar = !this.openSidebar
    },

    awaySidebar () {
      if (this.openSidebar) {
        this.openSidebar = false
      }
    },

    cardImage (img) {
      return img ? 'background-image: url(' + img + ')' : ''
    },

    toggleRotate () {
      this.gridListView = !this.gridListView
    },

    toggleMore () {
      this.moreVisible = !this.moreVisible
    },

    enableTimeline () {
      this.timelineSection = !this.timelineSection
    },

    enableBreakdown () {
      this.breakdownSection = !this.breakdownSection
    },

    enableTable () {
      this.tableSection = !this.tableSection
    },

    enableWhy () {
      this.whyCard = !this.whyCard
    },

    enableWhat () {
      this.whatCard = !this.whatCard
    },

    enableCompetitors () {
      this.competitorTable = !this.competitorTable
    },

    enableMembers () {
      this.membersTable = !this.membersTable
    },

    enableProducts () {
      this.productsTable = !this.productsTable
    },

    enablePromotion () {
      this.promotionTable = !this.promotionTable
    },

    enableCoverage () {
      this.coverageCard = !this.coverageCard
    },

    enableGrowth () {
      this.growthCard = !this.growthCard
    },

    checkMove (e) {
      const { element, futureIndex } = e.draggedContext

      // if (index === 0) {
      //   return false
      // }

      if (this.subscriptionIsFreeTrial) {
        if (!this.getIsBusinessGuideSectionFinished(element.sectionName)) {
          return false
        }

        if (futureIndex >= 7) {
          return false
        }
      }
    },

    async changeOrder (e) {
      const final = this.businessPlanSectionsFormated.filter(item => item.id !== 'section')

      const finalArrayForUpdate = final.map((item, index) => {
        item.orderNumber = index + 1

        return item
      })
      // this.$store.commit('idea/updatePositionsForJournalElement', e.moved)
      this.$store.commit('idea/updateJournalSwitchSidebarElement', finalArrayForUpdate)

      await this.save(finalArrayForUpdate)

      this.formatBusinessPlanSections()

      this.$nextTick(() => {
        this.setElementHeight([
          '.customer-card .top-segment h3',
          '.customer-card .top-segment .desc',
          '.customer-card .top-segment .pain-points',
          '.competitor-card .top-segment h3',
          '.promotion-card .card-bottom h3',
          '.distribution-card .card-bottom h3',
          '.distribution-card .card-bottom .desc',
          '.partner-card .card-bottom h3',
          '.partner-card .card-bottom .desc',
          '.product-card .card-bottom h3',
          '.product-card .card-bottom .category',
          '.member-card .card-bottom h3',
          '.member-card .card-bottom .position'
        ])
      })
    },

    onSkipQuickTour () {
      if (!this.isQuickTourCompleted('businessPlanQuickTour')) {
        this.completeQuickTour('businessPlanQuickTour')
      }
    },

    onCloseQuickTour () {
      this.quickTourVisible = false
      this.isPageContentVisible = true
    },

    toggleQuickTour () {
      this.quickTourVisible = !this.quickTourVisible
      this.isPageContentVisible = true

      this.$gtm.trackEvent({
        event: 'playIntro'
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.export-share-button {
  height: 34px;
  width: 37px;
  max-width: 118px;
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  transition: all ease-in-out .25s;

  &:hover {
    background: rgba(200, 198, 196, 0.20);
  }

  @include media-breakpoint-up($lg) {
    height: 50px;
    width: fit-content;
    max-width: fit-content;
    padding: 0 10px;
  }

  a {
    text-decoration: none;
    font-size: 17px;
  }
}
</style>
