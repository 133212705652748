<template>
  <div :class="{'text-center': textCenter}" :style="{ marginTop: topSpace }">
    <div
      v-if="title"
      class="d-flex align-center head-tick-mark-container"
      :class="{'justify-content-center': textCenter}"
    >
      <h1 class="ma-0 font-outfit-regular">
        {{ title }}
      </h1>
      <img
        v-if="showTickMark"
        :src="require('@/assets/img/icons/check-mark.svg')"
        alt="tick-mark"
        width="20"
      >
    </div>
    <p class="font-outfit-light" v-html="description" />
  </div>
</template>

<script>
export default {
  name: 'OnboardingWizardHeading',

  props: {
    title: {
      type: String,
      default: null
    },

    description: {
      type: String,
      required: true
    },

    textCenter: {
      type: Boolean,
      default: false
    },

    showTickMark: {
      type: Boolean,
      default: false
    },

    topSpace: {
      type: String,
      default: '8px'
    }
  }
}
</script>

<style scoped lang="scss">
.head-tick-mark-container {
  column-gap: 11px;
}

h1 {
  color: $color-navy;
  font-size: 22px;
}

p {
  font-size: 16px;
  margin: 10px 0 18px;
  opacity: .85;
}
</style>
