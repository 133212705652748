<template>
  <transition name="fade">
    <!-- Dashboard Header -->
    <div id="dashboard-header">
      <div class="header-wrapper justify-content-md-between flex-column flex-md-row">
        <div class="d-flex align-items-center mb-2 mb-md-0 align-self-start align-self-md-center"><!-- Title -->
          <h1 class="m-0" @click="openDialog">
            {{ title }}
            <!--            <img-->
            <!--              svg-inline-->
            <!--              src="@/assets/img/icons/check-mark.svg"-->
            <!--              alt=""-->
            <!--              class="completed-check"-->
            <!--            >-->
          </h1>
          <!-- /Title -->

          <!-- Switch -->
          <template v-if="stepStatusSwitchVisible">
            <step-status-switch :step-name="completedStepName" />
          </template>
          <!-- /Switch -->

          <template v-if="openPageBtnVisible">
            <open-business-plan
              v-if="statusOfCompleted"
              class="ml-3"
              :section-id="businessPlanSectionId"
            />
          </template>
        </div>
        <!-- Buttons wrapper -->
        <div
          class="d-flex align-items-center align-self-end align-self-md-center"
          :class="{
            'intro-active':
              $store.state.user.tutorial.guideAndExampleCompleted === false &&
              1 === 2
          }"
        >
          <slot name="actions" />

          <ai-buddy-button
            v-if="aiBuddyButtonVisible"
            :disabled="statusOfCompleted"
            responsive
            @click="$emit('open-ai-buddy-drawer')"
          />

          <info-button
            v-if="infoButtonVisible"
            responsive
            class="ml-2"
            @click="$emit('open-info')"
          />

          <!-- Quick tour -->
          <!--          <ib-quick-tour-button-->
          <!--            v-if="!hideQuickTourButton"-->
          <!--            :active="quickTourVisible"-->
          <!--            @click="toggleQuickTour"-->
          <!--          />-->
          <!-- /Quick tour -->

          <!-- Guide -->
          <!--          <ib-guide-button-->
          <!--            v-if="!hideGuideButton"-->
          <!--            :active="guideVisible"-->
          <!--            @click="toggleGuide"-->
          <!--          />-->
          <!-- /Guide -->

          <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
          <tutorial-tooltip
            v-if="$store.state.user.tutorial.guideAndExampleCompleted === false && 1 === 2"
            :title="'Need some help trough every step? Have no fear, Guide and Example are here!'"
            :text="'If you ever feel stuck <strong>Guide and Example can help you trough each step!</strong> Lorem ipsum dolor sit amet, consectetur, adipiscing elit praesent. Lorem ipsum dolor sit amet, consectetur, adipiscing elit praesent. Lorem ipsum dolor sit amet, consectetur.'"
            :tutorial-name="'guideAndExampleCompleted'"
          />
        <!-- /Tutorial Tooltip -->
        </div>
        <!-- /Buttons wrapper -->
      </div>
    </div>
    <!-- /Dashboard Header -->
  </transition>
</template>

<script>
// import IbGuideButton from '@/components/_v2/Button/IbGuideButton'
// import IbQuickTourButton from '@/components/_v2/Button/IbQuickTourButton'
import AiBuddyButton from '@/views/Home/StoryMode/Components/AiBuddyButton.vue'
import InfoButton from '@/views/Home/StoryMode/Components/InfoButton.vue'
import MixinHeaderScroll from '@/mixins/headerScroll'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import OpenBusinessPlan from '@/views/Home/StoryMode/Components/OpenBusinessPlan.vue'
import StepStatusSwitch from '@/views/Home/StoryMode/Components/StepStatusSwitch.vue'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import _find from 'lodash/find'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardHeader',

  components: {
    OpenBusinessPlan,
    InfoButton,
    AiBuddyButton,
    StepStatusSwitch,
    // IbQuickTourButton,
    TutorialTooltip
    // IbGuideButton
  },

  mixins: [
    MixinIdeaRoles,
    MixinHeaderScroll
  ],

  props: {
    title: {
      default: '',
      type: String
    },
    quickTourVisible: {
      type: Boolean,
      default: false
    },
    guideVisible: {
      default: false,
      type: Boolean
    },
    completedStepName: {
      type: String,
      default: ''
    },
    hideQuickTourButton: {
      type: Boolean,
      default: false
    },
    hideGuideButton: {
      type: Boolean,
      default: false
    },
    scrollHeader: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters('idea', {
      getStepIsCompleted: 'getStepIsCompleted',
      getBusinessPlanSections: 'getBusinessPlanSections'
    }),

    statusOfCompleted () {
      return this.getStepIsCompleted(this.completedStepName)
    },

    aiBuddyButtonVisible () {
      return !['idea-plan', 'business-guide', 'validation-idea-score', 'validation-idea-score-test', 'business-plan'].includes(this.$route.name)
    },

    infoButtonVisible () {
      return !['idea-plan', 'business-guide', 'validation-idea-score-test', 'business-plan'].includes(this.$route.name)
    },

    stepStatusSwitchVisible () {
      return !['idea-plan', 'business-guide', 'validation-idea-score', 'validation-idea-score-test', 'business-plan'].includes(this.$route.name)
    },

    openPageBtnVisible () {
      return !['idea-plan', 'business-guide', 'validation-idea-score', 'validation-idea-score-test', 'business-plan'].includes(this.$route.name)
    },

    businessPlanSectionId () {
      const mappedKeys = {
        ideaCompleted: 'Idea',
        productCompleted: 'Product',
        promotionCompleted: 'Promotion',
        distributionCompleted: 'Distribution',
        partnerCompleted: 'Partner',
        marketCompleted: 'Market',
        customersCompleted: 'Customers',
        competitorsCompleted: 'Competitors',
        swotAnalysisCompleted: 'SwotAnalysis',
        marketPotentialCompleted: 'MarketPotential',
        startupCostsCompleted: 'StartupCosts',
        financingCompleted: 'Financing',
        managementTeamCompleted: 'ManagementTeam',
        employeesAndPayrollCompleted: 'StaffAndPayroll',
        ownershipCompleted: 'Ownership',
        revenueStreamsCompleted: 'RevenueStreams',
        directCostsCompleted: 'DirectCosts',
        marketingBudgetCompleted: 'MarketingBudget',
        otherOverheadsCompleted: 'OtherOverheads',
        profitAndCashFlowCompleted: 'ProfitAndCashFlow'
      }

      const section = _find(this.getBusinessPlanSections, ['sectionName', mappedKeys[this.completedStepName]])

      return section ? section.id : null
    }
  },

  methods: {
    openDialog () {
      if (this.statusOfCompleted) {
        if (this.canEdit) this.$emit('open-comp-dialog')

        return true
      }

      return false
    },

    toggleQuickTour () {
      if (this.openDialog()) return

      if (this.guideVisible) {
        this.toggleGuide()
      }

      if (!this.quickTourVisible) {
        document.querySelector('.page-content').scrollTo(0, 0)
      }

      this.$emit('toggle-quick-tour', !this.quickTourVisible)
    },

    toggleGuide () {
      this.$emit('toggle-guide')
      this.$emit('toggle-quick-tour', false)

      // toDo new event for guide button
      this.$gtm.trackEvent({
        event: 'exampleButton'
      })

      if (!this.guideVisible) {
        document.querySelector('.page-content').scrollTo(0, 0)
      }
    }
  }
}
</script>
