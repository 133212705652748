import { render, staticRenderFns } from "./DialogAddProduct.vue?vue&type=template&id=3cc62c3a&scoped=true"
import script from "./DialogAddProduct.vue?vue&type=script&lang=js"
export * from "./DialogAddProduct.vue?vue&type=script&lang=js"
import style0 from "./DialogAddProduct.vue?vue&type=style&index=0&id=3cc62c3a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc62c3a",
  null
  
)

export default component.exports