<template>
  <div v-if="!loading && suggestions.length">
    <template v-for="(suggestion, index) in suggestions">
      <ib-tooltip
        :key="index"
        popper-class="suggestion"
        :open-delay="0"
        :disabled="$breakpoint.xs"
      >
        <!-- Tooltip Content -->
        <div slot="content">
          <div class="text-start">
            <p style="color: #FFFFFF; font-size: 15px;" class="m-0 bold">
              {{ suggestion.name }}
            </p>
            <p style="color: #D3DEF3; font-size: 13px" class="m-0">
              {{ suggestion.description }}
            </p>
          </div>
        </div>
        <!-- /Tooltip Content -->
        <ai-suggestion-tag
          :ref="`pillSuggestion${index}`"
          :selected="isSuggestionSelected(suggestion)"
          class="mr-2 cursor-pointer"
          @click="onClick(suggestion)"
        >
          {{ suggestion.name }}
        </ai-suggestion-tag>
      </ib-tooltip>
    </template>
  </div>
</template>

<script>
import AiSuggestionTag from '@/views/Home/StoryMode/Components/AiBuddy/AiSuggestionTag.vue'
import { aiPillAnimation } from '@/helpers/animations'

export default {
  name: 'AiSuggestionBasicParser',

  components: { AiSuggestionTag },

  props: {
    value: {
      required: true,
      validator: value => typeof value === 'object' || value === null
    },

    suggestions: {
      type: Array,
      required: true
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    loading (newValue) {
      if (!newValue) {
        if (this.suggestions) {
          setTimeout(() => {
            this.suggestions.forEach((item, index) => {
              const element = this.$refs[`pillSuggestion${index}`]
              if (element) {
                aiPillAnimation(element[0].$el, index)
              }
            })
          }, 1)
        }
      }
    }
  },

  methods: {
    onClick (suggestion) {
      this.$emit('input', suggestion)
      this.$emit('click', suggestion)
    },

    isSuggestionSelected (suggestion) {
      return this.value === suggestion
    }
  }
}
</script>
