<template>
  <div>
    <v-row dense>
      <v-col :cols="12">
        <onboarding-wizard-heading
          v-if="isUseAi"
          :title="$t('pages.onBoarding.step03.chooseYourBusinessIdeaType')"
          :description="$t('pages.onBoarding.step03.letAiSuggestTheBestOptions')"
          top-space="0"
          :show-tick-mark="localData.category !== null"
        />
        <onboarding-wizard-heading
          v-else
          :title="$t('pages.onBoarding.step03.chooseYourBusinessIdeaType')"
          :description="$t('pages.onBoarding.step03.chooseTheOptionFromTheList')"
          top-space="0"
          :show-tick-mark="localData.category !== null"
        />

        <div v-if="isUseAi" class="ib-button-generating-wrapper">
          <ib-button-generating
            height="45px"
            :loading="loading"
            @click="fetchSuggestions"
          >
            <i class="ib-icon ib-icon-magic-2" />
            <span class="font-outfit-regular">{{ $t('pages.onBoarding.step03.generateSuggestions') }}</span>
          </ib-button-generating>
        </div>

        <ib-handwriting
          v-if="showHandwritingText"
          class="my-5 ml-10"
          :text="`${$t('clickToGenerate')}`"
        >
          <template #arrow>
            <div class="position-relative">
              <img
                class="position-absolute"
                :src="require('@/assets/img/util/arrow-up.svg')"
                width="45"
                style="top: -28px"
                alt="arrow"
              >
            </div>
          </template>
        </ib-handwriting>

        <onboarding-suggestions
          ref="onboardingSuggestions"
          :suggestions="suggestions"
          :not-found-text="$t('pages.onBoarding.step03.noCategoryFound')"
          @click="setIdeaCategory($event)"
        />
      </v-col>

      <Transition name="slide">
        <v-col
          v-show="!firstClick"
          :cols="12"
        >
          <onboarding-wizard-heading
            v-if="isUseAi"
            :description="`${$t('pages.onBoarding.step03.chooseOneOfTheGeneratedOptions')}`"
          />

          <div class="autocomplete-wrapper">
            <v-autocomplete
              v-model="localData.category"
              :label="$t('pages.onBoarding.step03.ideaType')"
              item-value="id"
              :items="categories"
              :item-text="item => item.options ? item.options[lang].name : item.name"
              outlined
              dense
              height="46px"
              hide-details
              class="mb-2"
            >
              <!--          :prepend-icon="icons.mdiMagnify"-->
              <template #prepend-inner>
                <v-icon class="search-icon">
                  {{ icons.mdiMagnify }}
                </v-icon>
              </template>
            </v-autocomplete>
          </div>
        </v-col>
      </Transition>

      <Transition name="slide">
        <v-col
          v-show="template"
          :cols="12"
        >
          <onboarding-wizard-heading
            :title="$t('pages.onBoarding.step03.wouldYouLikeToUseTemplate')"
            :description="$t('pages.onBoarding.step03.startWithPreBuiltTemplate')"
            :show-tick-mark="localData.useTemplate !== null"
          />

          <v-chip-group
            v-model="localData.useTemplate"
            active-class="primary--text"
            column
            :mandatory="localData.useTemplate !== null"
            color="white"
          >
            <v-chip
              style="width: 133px; border: 1px solid #d9d9d9; height: 45px;"
              class="justify-content-center"
              filter
              label
              outlined
              :value="true"
              text-color="#292f4d"
            >
              {{ $t('yes') }}
            </v-chip>
            <v-chip
              style="width: 133px; border: 1px solid #d9d9d9; height: 45px;"
              class="justify-content-center"
              filter
              label
              outlined
              :value="false"
              text-color="#292f4d"
            >
              {{ $t('no') }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </Transition>
    </v-row>
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import IbButtonGenerating from '@/components/_v2/Button/IbButtonGenerating.vue'
import IbHandwriting from '@/components/_v2/IbHandwriting.vue'
import OnboardingSuggestions from '@/views/Onboarding/Components/OnboardingSuggestions.vue'
import OnboardingWizardHeading from '@/views/Onboarding/Components/OnboardingWizard/OnboardingWizardHeading.vue'
import _find from 'lodash/find'
import { mdiMagnify } from '@mdi/js'

export default {
  name: 'OnboardingThirdStep',

  components: { IbHandwriting, IbButtonGenerating, OnboardingSuggestions, OnboardingWizardHeading },

  inject: ['hasCategoryTemplate', 'currentLang', 'getIsUseAi', 'getCurrentStep'],

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          category: null,
          useTemplate: null
        }
      }
    },

    categories: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      localData: {
        category: null,
        useTemplate: null
      },
      loading: false,
      suggestions: [],
      localSuggestions: [],
      icons: { mdiMagnify },
      showHandwritingText: true,
      firstClick: true
    }
  },

  computed: {
    template () {
      return this.hasCategoryTemplate()
    },

    lang () {
      return this.currentLang()
    },

    isUseAi () {
      return this.getIsUseAi()
    },

    currentStep () {
      return this.getCurrentStep()
    }
  },

  watch: {
    'localData.category' () {
      if (this.currentStep === 3) {
        this.localData.useTemplate = null
      }
    }
  },

  created () {
    this.localData = this.data
  },

  mounted () {
    EventBus.$on('onboarding-form-changed', (payload) => {
      this.localData = payload
    })
  },

  beforeDestroy () {
    EventBus.$off('onboarding-form-changed')
  },

  methods: {
    async fetchSuggestions () {
      this.suggestions = []
      try {
        this.loading = true
        const response = await this.$http.post('ai/idea-category-suggestions', { description: this.localData.description })

        const suggestions = response.data.payload.data

        const array = []

        this.showHandwritingText = false
        this.firstClick = false

        if (suggestions.length) {
          suggestions.forEach(suggestion => {
            const itemExist = _find(this.categories, ['name', suggestion])

            if (itemExist) {
              array.push(itemExist)
            }
          })
        }

        if (array.length) {
          this.suggestions = array.map(item => item.options[this.lang].name)

          this.localSuggestions = array.map(item => {
            return {
              id: item.id,
              name: item.options[this.lang].name
            }
          })
        }

        if (!array.length) this.$refs.onboardingSuggestions.notFound = true

        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },

    setIdeaCategory (name) {
      this.localData.category = _find(this.localSuggestions, ['name', name]).id
    }
  }
}
</script>

<style scoped lang="scss">

.search-icon {
  margin-top: 2px;
}

</style>
